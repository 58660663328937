import { useState, useEffect } from 'react';
import { Grid, Paper, Typography, List, ListItem, ListItemText, Button } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import LiensInternes from '../../services/liens';
import { useNavigate } from 'react-router-dom';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';

const useStyles = makeStyles((theme: any) => ({
    page: {
        flexGrow: 1,
        marginTop: 90,
        marginBottom: 2,
    },
    paper: {
        padding: 1,
        paddingTop: 15,
        textAlign: 'center',
        color: 'black',
        height: '85vh',
        overflow: 'auto',
    },
    title: {
        paddingBottom: 15,
    },
    listContainer: {
        borderTop: `1px solid ${'grey'}`,
        backgroundColor: '#DFDFDF',
        maxHeight: '80vh',
        overflow: 'auto',
    },
    listItem: {
        borderBottom: `1px solid ${'grey'}`,
    },
    bodyMail: {
        padding: 20,
    },
    mail: {
        cursor: 'pointer',
    }
}));

function LayoutMessagerie() {
    const exempleMails = [
        {
            id: 1,
            from: "agent1@example.com",
            to: "userFinal@example.com",
            subject: "Où on en est ma demande",
            date: "2023-04-02T09:00:00Z",
            snippet: "Bonjour User,\nSuite à votre demande d'e-visa hier, vous aurez le résultat de...",
            body: "<p>Bonjour User,</p><p>Suite à votre demande d'e-visa hier, vous aurez le résultat de votre demande en début d'après-midi</p>",
        },
        {
            id: 2,
            from: "agent1@example.com",
            to: "userFinal@example.com",
            subject: "Problème de document",
            date: "2023-03-11T13:24:00Z",
            snippet: "Bonjour User,\nJe vous confirme la réception de votre passeport sous format...",
            body: "<p>Bonjour User,</p><p>Je vous confirme la réception de votre passeport sous format numérique. En effet lors de votre première demande d'e-visa, l'image du passeport que vous nous avez envoyé était floue.</p>",
        },
        {
            id: 3,
            from: "agent2@example.com",
            to: "userFinal@example.com",
            subject: "Informations supplémentaires",
            date: "2021-03-09T14:30:00Z",
            snippet: "Bonjour User,\nIl faut en effet préciser la raison de votre voyage si elle ne...",
            body: "<p>Bonjour User,</p><p>Il faut en effet préciser la raison de votre voyage, si celle-ci ne correspond pas parmi la liste prédéfinie.</p>",
        },
    ];

    const classes = useStyles();
    const [selectedMail, setSelectedMail] = useState(exempleMails[1]);
    const [mails, setMails] = useState(exempleMails);
    const navigate = useNavigate();

    const handleListItemClick = (mail: any) => {
        setSelectedMail(mail);
    };

    return (
        <div className={classes.page}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <Paper className={classes.paper}>
                        <Typography className={classes.title} variant="h6">Boîte de réception</Typography>
                        <List className={classes.listContainer}>
                            {mails.map((mail) => (
                                <ListItem key={mail.id} onClick={() => handleListItemClick(mail)} className={classes.listItem}>
                                    <ListItemText className={classes.mail} primary={mail.subject} secondary={mail.snippet} />
                                </ListItem>
                            ))}
                        </List>
                        <Button style={{ margin: 50 }} onClick={() => navigate(window.location.href.includes("utilisateur") ? LiensInternes.USER.BASE : LiensInternes.AGENT.BASE)} variant="contained">
                            <KeyboardReturnIcon fontSize='small' style={{ marginRight: 5 }}/> {"Retour sur le menu"}
                        </Button>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Paper className={classes.paper}>
                        {selectedMail ? (
                            <div className={classes.bodyMail}>
                                <Typography variant="h6">{selectedMail.subject}</Typography>
                                <Typography variant="subtitle2">
                                    De: {selectedMail.from} | Pour : {selectedMail.to} | Date: {selectedMail.date}
                                </Typography>
                                <div dangerouslySetInnerHTML={{ __html: selectedMail.body }}></div>
                            </div>
                        ) : (
                            <Typography variant="subtitle1">Cliquez sur un e-mail pour afficher son contenu</Typography>
                        )}
                        <Button style={{ margin: 40 }} onClick={() => navigate(window.location.href.includes("utilisateur") ? LiensInternes.USER.BASE : LiensInternes.AGENT.BASE)} variant="contained">
                            <DriveFileRenameOutlineIcon fontSize='small' style={{ marginRight: 5 }} /> {"Répondre"}
                        </Button>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
}

export default LayoutMessagerie;