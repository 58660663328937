import { Box } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Navigate, Routes, Route } from "react-router-dom";
import DashboardNavBar from "../../components/navbar/DashboardNavBar/DashboardNavBar";
import LiensInternes from "../../services/liens";
import DetailsDemande from "../UserFinal/DetailsDemande/DetailsDemande";
import DashboardPaf from "./DashboardPaf/DashboardPaf";
import ArriveesJours from "./ArriveesJours/ArriveesJours";
import PafCategoryB from "./PafCategoryB/PafAllCategorie";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import HowToRegIcon from '@mui/icons-material/HowToReg';
import MailIcon from "@mui/icons-material/Mail";


const drawerWidth = 240;

function LayoutPaf() {
	const { t } = useTranslation();

	const listItem = [
		{
			nom: t("Tableau de bord"),
			url: LiensInternes.PAF.BASE + LiensInternes.PAF.DASHBOARD,
			picto: <InboxIcon />,
		},
		{
			nom: "Arrivées du jour",
			url: LiensInternes.PAF.BASE + LiensInternes.PAF.ARRIVEE_JOUR,
			picto: <HowToRegIcon />,
		},
		{
			nom: "Voir toutes les demandes",
			url: LiensInternes.PAF.BASE + LiensInternes.PAF.TOUTES_DEMANDES,
			picto: <MailIcon />,
		},
	];

	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "space-between",
				width: "100%",
				position: "relative",
				zIndex: 1,
			}}
		>
			<DashboardNavBar listItem={listItem} />
			<Box
				component="main"
				className="layout-dashboard"
				sx={{
					width: { sm: `calc(100% - ${drawerWidth}px)` },
				}}
			>
				<Routes>
					<Route
						path="/"
						element={<Navigate to={LiensInternes.PAF.BASE + LiensInternes.PAF.DASHBOARD} />}
					/>
					<Route path={LiensInternes.PAF.DASHBOARD} element={<DashboardPaf />} />
					<Route path={LiensInternes.PAF.TOUTES_DEMANDES} element={<PafCategoryB />} />
					<Route path={LiensInternes.PAF.ARRIVEE_JOUR} element={<ArriveesJours />} />
					<Route path={LiensInternes.DETAIL_DEMANDE + "/:id"} element={<DetailsDemande role={"paf"} />} />
				</Routes>
			</Box>
		</Box>
	);
}

export default LayoutPaf;
