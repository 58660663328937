import React from "react";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import "./DashboardNavBar.scss";

import { NavLink } from "react-router-dom";

interface IDashboardNavBar {
	listItem: {
		nom: string;
		url: string;
		picto: any;
	}[];
}

const drawerWidth = 240;

function DashboardNavBar({ listItem }: IDashboardNavBar) {
	return (
		<Drawer
			variant="permanent"
			sx={{
				width: drawerWidth,
				flexShrink: 0,
				[`& .MuiDrawer-paper`]: {
					width: drawerWidth,
					boxSizing: "border-box",
					zIndex: "10",
				},
			}}
		>
			<Toolbar />
			<Box sx={{ overflow: "auto" }}>
				<List>
					{listItem.map((item) => (
						<ListItem disablePadding>
							<NavLink
								to={item.url}
								className={({ isActive }) =>
									["link_nav", isActive ? "active" : null]
										.filter(Boolean)
										.join(" ")
								}
							>
								<ListItemButton>
									<ListItemIcon>{item.picto}</ListItemIcon>
									<ListItemText>{item.nom}</ListItemText>
								</ListItemButton>
							</NavLink>
						</ListItem>
					))}
				</List>
				<Divider />
			</Box>
		</Drawer>
	);
}

export default DashboardNavBar;
