import { Box, Modal, Typography } from "@mui/material";
import React from "react";
import passport from "../../../pictures/passport.jpg";
import billet from "../../../pictures/billet_avion.jpg";
import hebergement from "../../../pictures/hotel.jpg";

const style = {
	position: "absolute" as "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 1000,
	height: 800,
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 4,
};

interface IModalePatternPdf {
	open: boolean;
	setOpen: Function;
	sourcePdf: any;
}

function ModalePatternPdf({ open, setOpen, sourcePdf }: IModalePatternPdf) {
	const handleClose = () => setOpen(false);

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={style}>
				<iframe src={sourcePdf} frameBorder="0" scrolling="auto" height="100%" width="100%"></iframe>
			</Box>
		</Modal>
	);
}

export default ModalePatternPdf;
