import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement // <--- enregistrement de BarElement
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
  },
};

function BarChartPattern() {
  const { t } = useTranslation();

  const labels = [
    t("JOUR_SEMAINE.lundi"),
    t("JOUR_SEMAINE.mardi"),
    t("JOUR_SEMAINE.mercredi"),
    t("JOUR_SEMAINE.jeudi"),
    t("JOUR_SEMAINE.vendredi"),
    t("JOUR_SEMAINE.samedi"),
    t("JOUR_SEMAINE.dimanche"),
  ];

  const data = {
    labels,
    datasets: [
      {
        label: "Catégorie B",
        data: [30, 85, 40, 57, 65, 18, 36],
        backgroundColor: "rgb(34, 148, 25)",
      },
      {
        label: "Catégorie C",
        data: [51, 56, 82, 20, 47, 16, 89],
        backgroundColor: "rgb(255, 211, 31)",
      },
    ],
  };

  return <Bar options={options} data={data} />;
}

export default BarChartPattern;
