import { TRUE } from "sass";
import { TYPES } from "../actions/actionTypes";
import { MesDemandesAction, MesDemandesState } from "../Interfaces/typeMesDemandes";
import { IUser, UserAction, UserState } from "../Interfaces/typeUser";

const initialState : MesDemandesState = 
  [
{		id: 1,
		date: "12/12/2022",
		step: 2,
		new: true,
    message : 1,
  },
{		id: 2,
		date: "11/12/2022",
		step: 1,
		new: false,
    message : 2,
  },
{		id: 3,
		date: "10/10/2022",
		step: 3,
		new: false,
    message : 0,
  },
{		id: 4,
		date: "10/10/2022",
		step: 3,
		new: false,
    message : 0,
  }
  ]


const mesDemandesReducer = (
  state: MesDemandesState = initialState,
  action: MesDemandesAction
): MesDemandesState => {
  switch (action.type) {
    // case TYPES.USER.ADD:
    //   const newUser: IUser = {
    //     id: Math.random(), // not really unique
    //     email: action.user.email,
    //     password: action.user.password,
    //   }
    //   return {
    //     ...state,
    //     user: state.user.concat(newUser),
    //   }
    // case TYPES.USER.REMOVE:
    //   const removeUser: IUser[] = state.filter(
    //     usr => usr.id !== action.user.id
    //   )
    //   return {
    //     removeUser
    //   }
  }
  return state
}

export default mesDemandesReducer
