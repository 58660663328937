import {
	Box,
	Container,
	Stepper,
	Step,
	StepLabel,
	Button,
} from "@mui/material";
import React, { useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { redirectUrl, redirectUrlAgent } from "../../services/functions/stepperCreationVisaRoutes";
import LiensInternes from "../../services/liens";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import "./CreationVisa.scss";
import { useTranslation } from "react-i18next";


function CreationVisa() {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [isAnAgent, setIsAnAgent] = React.useState(false);
	const [activeStep, setActiveStep] = React.useState(0);

	const [professionUser, setProfessionUser] = React.useState("");

	const steps = isAnAgent ?
		[
			t("CREATION_VISA.ALL_STEP.STEP1"),
			t("CREATION_VISA.ALL_STEP.STEP2"),
			t("CREATION_VISA.ALL_STEP.STEP3"),
			t("CREATION_VISA.ALL_STEP.STEP5"),
		] : [
			t("CREATION_VISA.ALL_STEP.STEP1"),
			t("CREATION_VISA.ALL_STEP.STEP2"),
			t("CREATION_VISA.ALL_STEP.STEP3"),
			t("CREATION_VISA.ALL_STEP.STEP4"),
			t("CREATION_VISA.ALL_STEP.STEP5"),
		];
	useEffect(() => {
		if (window.location.href.includes("step-2")) {
			setActiveStep(1);
		}
		if (window.location.href.includes("agent")) {
			setIsAnAgent(true)
		} else {
			setIsAnAgent(false)
		}
	}, []);

	const handleNext = () => {
		console.log("number lenght", steps.length)
		if (activeStep === steps.length - 1) {
			isAnAgent ? navigate(LiensInternes.AGENT.BASE + LiensInternes.AGENT.DASHBOARD) :
			navigate(LiensInternes.USER.BASE + LiensInternes.USER.DASHBOARD);
		} else {
			setActiveStep(activeStep + 1);
			isAnAgent ? navigate(redirectUrlAgent(activeStep + 1)) : navigate(redirectUrl(activeStep + 1));
		}
	};

	const handleBack = () => {
		setActiveStep(activeStep - 1);
		isAnAgent ? navigate(redirectUrlAgent(activeStep - 1)) : navigate(redirectUrl(activeStep - 1));
	};

	return (
		<Container maxWidth="xl" className="container-main">
			<Box className="box-main">
				<Stepper activeStep={activeStep} alternativeLabel>
					{steps.map((label) => (
						<Step key={label}>
							<StepLabel>{label}</StepLabel>
						</Step>
					))}
				</Stepper>

				<Box className="box-contenu">
					<Routes>
						<Route
							path="/"
							element={
								<Navigate
									to={
										isAnAgent ? LiensInternes.AGENT.BASE + LiensInternes.CREATION_VISA + LiensInternes.STEP_01 :
											LiensInternes.CREATION_VISA +
											LiensInternes.STEP_01
									}
								/>
							}
						/>
						{isAnAgent ?
							<>
								<Route
									path={LiensInternes.STEP_01}
									element={<Step1 isAnAgent={true} professionUser={professionUser} setProfessionUser={setProfessionUser}/>}
								/>
								<Route
									path={LiensInternes.STEP_02}
									element={<Step2 isAnAgent={true} />}
								/>
								<Route
									path={LiensInternes.STEP_03}
									element={<Step3 isAnAgent={true} professionUser={professionUser}/>}
								/>
								<Route
									path={LiensInternes.STEP_05}
									element={<Step5 isAnAgent={true} setActiveStep={setActiveStep} />}
								/>
							</> : <>
								<Route
									path={LiensInternes.STEP_01}
									element={<Step1 isAnAgent={false} />}
								/>
								<Route
									path={LiensInternes.STEP_02}
									element={<Step2 isAnAgent={false} />}
								/>
								<Route
									path={LiensInternes.STEP_03}
									element={<Step3 isAnAgent={false} />}
								/>
								<Route
									path={LiensInternes.STEP_04}
									element={<Step4 isAnAgent={false} />}
								/>
								<Route
									path={LiensInternes.STEP_05}
									element={<Step5 isAnAgent={false} />}
								/>
							</>}
					</Routes>
				</Box>

				{/* BOUTONS  */}

				<>
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "space-around",
							flexDirection: "row",
							marginTop: 2
						}}
					>
						<Button
							color="inherit"
							disabled={activeStep === 0}
							onClick={handleBack}
							sx={{ mr: 1 }}
						>
							Retour
						</Button>
						<Box sx={{ flex: "1 1 auto" }} />
						<Button onClick={handleNext}>
							{activeStep === steps.length - 1
								? "Terminer"
								: "Suivant"}
						</Button>
					</Box>
				</>
			</Box>
		</Container>
	);
}

export default CreationVisa;
