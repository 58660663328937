import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import TablePattern from "../../../components/Table/TablePattern";
import TableMesDemandes from "../../../components/TableMesDemandes/TableMesDemandes";
import { IRedux } from "../../../redux/reducers";
import LiensInternes from "../../../services/liens";

function ToutesDemandes() {
	const { t } = useTranslation();

	const mesDemandes = useSelector((state: IRedux) => state.mesDemandes);

	return (
		<div>
			<Typography
				variant="h3"
				component="div"
				sx={{
					flexGrow: 1,
				}}
			>
				{t("USER_FINAL.toutesDemandes.title")}
			</Typography>
			<TableMesDemandes
				role="client"
				data={mesDemandes}
				buttonVoirTout={false}
				btnVoirPlusUrl={LiensInternes.USER.BASE + LiensInternes.USER.SUIVRE_DEMANDE}
				btnMessagerieUrl={LiensInternes.USER.BASE + LiensInternes.MESSAGERIE}
			/>
		</div>
	);
}

export default ToutesDemandes;
