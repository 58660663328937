import { Button, Typography, Grid, IconButton, CardContent, TextField, Box } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useNavigate } from "react-router-dom";
import LiensInternes from "../../../services/liens";
import Swal from "sweetalert2";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	display: "flex",
	justifyContent: "center",
	textAlign: "center",
	margin: "2rem",
	height: "15rem",
	width: "15rem",
	color: theme.palette.text.secondary,
}));

function DemandesAgent() {
	const { t } = useTranslation();
	const navigate = useNavigate();

	// const listePersonne = [
	// 	{
	// 		nom: "Julien",
	// 		prenom: "Trésol",
	// 		numeroPassport: "0678654782165886",
	// 	},
	// 	{
	// 		nom: "Amélie",
	// 		prenom: "Dubois",
	// 		numeroPassport: "0678654782165886",
	// 	},
	// 	{
	// 		nom: "Paul",
	// 		prenom: "Langlois",
	// 		numeroPassport: "0678654782165886",
	// 	},
	// ];

	function handleClick() {
		Swal.fire("Vos données ont bien été enregistrées");
	}
	return (
		<div>
			<Typography
				variant="h4"
				component="div"
				sx={{
					flexGrow: 1,
				}}
			>
				{t("AGENT.demandeAgent.title")}
			</Typography>
			<Box sx={{ display: "flex", alignItems: "center", marginTop: 5 }}>
				<TextField sx={{ marginRight: 5, width: 500 }} label="Nom de la demande" variant="outlined" />
				<Button onClick={() => navigate(LiensInternes.AGENT.BASE + LiensInternes.CREATION_VISA + LiensInternes.STEP_01)} sx={{ height: 55}} variant="contained" color="primary" >
					<AddCircleIcon sx={{ marginRight: 1}}/>{t("AGENT.demandeAgent.boutonCreationDemande")}
				</Button>
			</Box>
			{/* <Grid container spacing={2} sx={{ marginTop: 5 }}>
				<Grid xs={3}>
					<Item onClick={() => navigate(LiensInternes.AGENT.BASE + LiensInternes.CREATION_VISA + LiensInternes.STEP_01)}>
						<Button>
							<AddCircleOutlineIcon />
						</Button>
					</Item>
				</Grid>

				{listePersonne.map((personne) => (
					<Grid xs={3}>
						<Item>
							<CardContent>
								<Typography variant="h5" component="div">
									{personne.nom} {personne.prenom}
								</Typography>
								<Typography sx={{ mb: 1.5 }} color="text.secondary">
									{personne.numeroPassport}
								</Typography>
							</CardContent>
						</Item>
					</Grid>
				))}
				<Button variant="contained" className="bouton-affecter" size="large" onClick={handleClick}>
					{t("AGENT.demandeAgent.boutonEnvoiDemande")}
				</Button>
			</Grid> */}
		</div>
	);
}

export default DemandesAgent;
