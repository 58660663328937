import { Autocomplete, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import TablePattern from "../../../components/Table/TablePattern";
import { IUser } from "../../../redux/Interfaces/typeUser";
import { IRedux } from "../../../redux/reducers";
import LiensInternes from "../../../services/liens";
import PaginationPattern from "../../../components/Pagination";

function ToutesMesDemandes() {
    const { t } = useTranslation();
    const user = useSelector((state: IRedux) => state.user);

    const [allUser, setAllUser] = useState<IUser[]>([]);

    const options = ['Reel it Fev 2023', 'CMA CGM Janv 2023', 'Hcube Janv 2023', 'Reel it Nov 2022'];
    const [searchValue, setSearchValue] = useState<string | null>(null);

    useEffect(() => {
        setAllUser(user)
    });
    return (
        <div>
            <Typography
                variant="h3"
                component="div"
                sx={{
                    flexGrow: 1,
                }}
            >
                {t("DST.enAttenteAvis.title")}
            </Typography>
            <Autocomplete
                options={options}
                value={searchValue}
                onChange={(event, newValue) => {
                    setSearchValue(newValue);
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Rechercher une demande"
                        variant="outlined"
                    />
                )}
                sx={{
                    marginTop: 5
                }}
            />
            <TablePattern
                role="autorite"
                checkbox={true}
                data={allUser}
                buttonVoirTout={false}
                btnVoirPlusUrl={LiensInternes.MINISTERE.SUPERVISEUR_BASE + LiensInternes.DETAIL_DEMANDE}
            />
            <PaginationPattern />
        </div>
    );
}

export default ToutesMesDemandes;
