import LiensInternes from "../liens";

export function redirectUrl(step) {
	switch (step) {
		case 0:
			return LiensInternes.CREATION_VISA + LiensInternes.STEP_01;
		case 1:
			return LiensInternes.CREATION_VISA + LiensInternes.STEP_02;
		case 2:
			return LiensInternes.CREATION_VISA + LiensInternes.STEP_03;
		case 3:
			return LiensInternes.CREATION_VISA + LiensInternes.STEP_04;
		case 4:
			return LiensInternes.CREATION_VISA + LiensInternes.STEP_05;
		default:
			return LiensInternes.CREATION_VISA + LiensInternes.STEP_01;
	}
}

export function redirectUrlAgent(step) {
	switch (step) {
		case 0:
			return LiensInternes.AGENT.BASE + LiensInternes.CREATION_VISA + LiensInternes.STEP_01;
		case 1:
			return LiensInternes.AGENT.BASE + LiensInternes.CREATION_VISA + LiensInternes.STEP_02;
		case 2:
			return LiensInternes.AGENT.BASE + LiensInternes.CREATION_VISA + LiensInternes.STEP_03;
		case 3:
			return LiensInternes.AGENT.BASE + LiensInternes.CREATION_VISA + LiensInternes.STEP_05;
		default:
			return LiensInternes.AGENT.BASE + LiensInternes.CREATION_VISA + LiensInternes.STEP_01;
	}
}
