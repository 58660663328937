import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Avatar } from "@mui/material";

function CardHome(props:any) {
    const { title, description, image, colorBackground, colorTexteTitle } = props;
    return (
        <div>
            <Card sx={{ display: "flex", p: 2, minHeight: "550px" }}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <CardContent sx={{ flex: "1 0 auto" }}>
                        <Avatar
                            sx={{ bgcolor: colorBackground, width: 70, height: 70}}
                            variant="rounded"
                        >
                            {image}
                        </Avatar>
                    </CardContent>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", p:1 }}>
                <Typography variant="h5" component="div" sx={{ flexGrow: 1, p:1, color: colorTexteTitle, fontWeight: 'bold' }}>
                    {title}
                </Typography>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1, p:1 }} dangerouslySetInnerHTML={{ __html: description }}/>
                </Box>
            </Card>
        </div>
    );
}

export default CardHome;
