import { Alert } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

interface ISwitchDetailAvancement {
	step: number;
}

function SwitchDetailAvancement({ step }: ISwitchDetailAvancement) {
	const { t } = useTranslation();

	switch (step) {
		case 3:
			return (
				<div>
					<Alert severity="success" className="alerte-custom">
						{t("ETAPES_AVANCEMENT.accepte")}
					</Alert>
					<Alert severity="success" className="alerte-custom">
						{t("ETAPES_AVANCEMENT.ministère")}
					</Alert>
					<Alert severity="success" className="alerte-custom">
						{t("ETAPES_AVANCEMENT.dst")}
					</Alert>
				</div>
			);
		case 4:
			return (
				<div>
					<Alert severity="error" className="alerte-custom">
						{t("ETAPES_AVANCEMENT.refuse")}
					</Alert>
					<Alert severity="error" className="alerte-custom">
						{t("ETAPES_AVANCEMENT.ministère")}
					</Alert>
					<Alert severity="warning" className="alerte-custom">
						{t("ETAPES_AVANCEMENT.dst")}
					</Alert>
				</div>
			);
		case 1:
			return (
				<div>
					<Alert severity="info" className="alerte-custom">
						{t("ETAPES_AVANCEMENT.dst")}
					</Alert>
				</div>
			);
		case 2:
			return (
				<div>
					<Alert severity="info" className="alerte-custom">
						{t("ETAPES_AVANCEMENT.ministère")}
					</Alert>
					<Alert severity="success" className="alerte-custom">
						{t("ETAPES_AVANCEMENT.dst")}
					</Alert>
				</div>
			);
		default:
			return (
				<div>
					<Alert severity="success" className="alerte-custom">
						{t("ETAPES_AVANCEMENT.accepte")}
					</Alert>
					<Alert severity="success" className="alerte-custom">
						{t("ETAPES_AVANCEMENT.ministère")}
					</Alert>
					<Alert severity="success" className="alerte-custom">
						{t("ETAPES_AVANCEMENT.dst")}
					</Alert>
				</div>
			);
	}
}

export default SwitchDetailAvancement;
