import React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

interface ISelectPattern {
	label: string;
	data: { value: string; label: string }[];
	onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void; 
}

function SelectPattern({ label, data, onChange }: ISelectPattern) {
	const [selectedValue, setSelectedValue] = React.useState("");
  
	const handleChange = (event: SelectChangeEvent<string>) => {
		const value = event.target.value as string;
		setSelectedValue(value);
		if (typeof onChange === "function") {
		  const selectEvent = {
			target: {
			  value,
			  name: event.target.name
			}
		  };
		  onChange(selectEvent as React.ChangeEvent<HTMLSelectElement>);
		}
	  };
  
	return (
	  <Box sx={{ minWidth: 120, margin: "0.5rem" }}>
		<FormControl fullWidth>
		  <InputLabel id="demo-simple-select-label">{label}</InputLabel>
		  <Select
			labelId="demo-simple-select-label"
			id="demo-simple-select"
			value={selectedValue}
			label={label}
			onChange={handleChange}
		  >
			{data.map((d) => (
			  <MenuItem key={d.value} value={d.value}>{d.label}</MenuItem>
			))}
		  </Select>
		</FormControl>
	  </Box>
	);
  }

export default SelectPattern;
