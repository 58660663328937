import { TextField } from "@mui/material";
import React from "react";

interface ITextFieldPattern {
	label: string;
	multipline?: boolean;
	rowMultiline?: number;
}

function TextFieldPattern({
	label,
	multipline,
	rowMultiline,
}: ITextFieldPattern) {
	return (
		<div>
			<TextField
				sx={{
					margin: "0.5rem",
				}}
				fullWidth
				id="outlined-basic"
				label={label}
				variant="outlined"
				multiline={multipline}
				rows={rowMultiline}
			/>
		</div>
	);
}

export default TextFieldPattern;
