import { Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import DoughnutPattern from "../../../components/Statistiques/DoughnutPattern";
import LineChartPattern from "../../../components/Statistiques/LineChartPattern";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import BarChartPattern from "../../../components/Statistiques/BarChartPattern";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	margin: "0.5rem",
	marginTop: "3rem",
	color: theme.palette.text.secondary,
}));

function DashboardMinistere() {
	const { t } = useTranslation();
	return (
		<div>
			<Typography
				variant="h3"
				component="div"
				sx={{
					flexGrow: 1,
				}}
			>
				{t("MINISTERE.dashboard.title")}
			</Typography>

			<Grid container spacing={2}>
				<Grid xs={8}>
					<Item>
						<Typography
							variant="h6"
							component="div"
							sx={{
								flexGrow: 1,
							}}
						>
							{t("DST.dashboard.nombreDemandeJour")}
						</Typography>
						<BarChartPattern />
					</Item>
				</Grid>
				<Grid xs={4}>
					<Item>
						<Typography
							variant="h6"
							component="div"
							sx={{
								flexGrow: 1,
							}}
						>
							{t("DST.dashboard.demandesParAgent")}
						</Typography>
						<DoughnutPattern />
					</Item>
				</Grid>
			</Grid>
		</div>
	);
}

export default DashboardMinistere;
