import Typography from "@mui/material/Typography";
import { t } from "i18next";
import React, { useState } from "react";
import Button from "@mui/material/Button";
import { Box, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, TextField } from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useNavigate } from "react-router-dom";
import LiensInternes from "../../services/liens";


function Step0() {

    const [nom, setNom] = useState('');
    const [prenom, setPrenom] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [open, setOpen] = useState(false);

    const navigate = useNavigate();

    const handleSubmit = (event: any) => {
        event.preventDefault();
        console.log('Nom:', nom);
        console.log('Prenom:', prenom);
        console.log('Email:', email);
        console.log('Password:', password);
        console.log('Confirm Password:', confirmPassword);
        setOpen(true)
    };

    const handleConfirm = () => {
        setOpen(false);
        navigate(LiensInternes.CONNEXION)
    }

    let test = t("CREATION_VISA.STEP0.descriptionStep")
    return (
        <Container maxWidth="xl" className="container-main">
            <Box className="box-main">
                <Typography
                    align="center"
                    gutterBottom
                    dangerouslySetInnerHTML={{ __html: test }}
                />
                <Paper style={{ padding: 30, maxWidth: '70%', margin: '0 auto' }}>
                    <form
                        onSubmit={handleSubmit}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            margin: "5px auto 0",
                            marginBottom: "5px"
                        }}
                    >
                        <Typography align="center" variant="h5" gutterBottom>
                            {t("CREATION_VISA.STEP0.title")}
                        </Typography>
                        <TextField
                            label="Nom"
                            variant="outlined"
                            type="text"
                            value={nom}
                            onChange={(event) => setNom(event.target.value)}
                            margin="normal"
                            required
                            fullWidth
                        />
                        <TextField
                            label="Prénom"
                            variant="outlined"
                            type="text"
                            value={prenom}
                            onChange={(event) => setPrenom(event.target.value)}
                            margin="normal"
                            required
                            fullWidth
                        />
                        <TextField
                            label="Email"
                            variant="outlined"
                            type="email"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                            margin="normal"
                            required
                            fullWidth
                        />
                        <TextField
                            label="Mot de passe"
                            variant="outlined"
                            type="password"
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}
                            margin="normal"
                            required
                            fullWidth
                        />
                        <TextField
                            label="Confirmer votre mot de passe"
                            variant="outlined"
                            type="password"
                            value={confirmPassword}
                            onChange={(event) => setConfirmPassword(event.target.value)}
                            margin="normal"
                            required
                            fullWidth
                        />
                        <Button type="submit" variant="contained" color="primary" fullWidth style={{ display: "flex", margin: '0 auto', maxWidth: '30%', marginTop: 20 }}>
                            Créer mon compte
                        </Button>
                    </form>
                </Paper>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Dialog open={open} onClose={() => setOpen(false)}>
                        <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}><CheckCircleOutlineIcon sx={{ marginRight: 1 }} fontSize="large" color="success" />{t("CREATION_VISA.STEP0.titreCompteCree")}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {t("CREATION_VISA.STEP0.descriptionCompteCree")}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button onClick={() => handleConfirm()}>{t("CREATION_VISA.STEP0.boutonOK")}</Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            </Box>
        </Container>
    );
}

export default Step0;
