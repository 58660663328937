import { Grid, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SelectPattern from "../../components/formPatterns/selectPattern/SelectPattern";
import TextFieldPattern from "../../components/formPatterns/textFieldPattern/TextFieldPattern";
import DatePickerPattern from "../../components/formPatterns/datePickerPattern/DatePickerPattern";

interface IStep2 {
	isAnAgent: boolean;
}

function Step2({ isAnAgent }: IStep2) {
	const { t } = useTranslation();
	const [reason, setReason] = useState("");
	const [showOtherReason, setShowOtherReason] = useState(false);
	const [arrivalMethod, setArrivalMethod] = useState({
		byLand: false,
		byAir: false,
		bySea: false
	});
	const [typeHousing, setTypeHousing] = useState({
		hotel: false,
		camping: false,
		habitant: false,
		gite: false,
		aubergeJeunesse: false,
		autre: false
	});

	const handleArrivalMethodChange = (e: any) => {
		setArrivalMethod({
			byLand: e.target.name === "byLand" ? e.target.checked : false,
			byAir: e.target.name === "byAir" ? e.target.checked : false,
			bySea: e.target.name === "bySea" ? e.target.checked : false,
		});
	};

	const handleTypeHousing = (e: any) => {
		setTypeHousing({
			hotel: e.target.name === "hotel" ? e.target.checked : false,
			camping: e.target.name === "camping" ? e.target.checked : false,
			habitant: e.target.name === "habitant" ? e.target.checked : false,
			aubergeJeunesse: e.target.name === "aubergeJeunesse" ? e.target.checked : false,
			gite: e.target.name === "gite" ? e.target.checked : false,
			autre: e.target.name === "autre" ? e.target.checked : false,
		});
	};

	return (
		isAnAgent ? <div>
			<Typography sx={{ marginLeft: "1%", marginTop: "3%", fontWeight: "bold" }}>{t("CREATION_VISA.STEP2.moyenArrivee")}</Typography>
			<div style={{ margin: "1%" }}>
				<FormControlLabel
					control={
						<Checkbox
							checked={arrivalMethod.byLand}
							onChange={handleArrivalMethodChange}
							name="byLand"
						/>
					}
					label={t("MOYEN_ARRIVEE.terrestre")}
				/>
				<FormControlLabel
					sx={{ marginLeft: "3%" }}
					control={
						<Checkbox
							checked={arrivalMethod.byAir}
							onChange={handleArrivalMethodChange}
							name="byAir"
						/>
					}
					label={t("MOYEN_ARRIVEE.aerien")}
				/>
				<FormControlLabel
					sx={{ marginLeft: "3%" }}
					control={
						<Checkbox
							checked={arrivalMethod.bySea}
							onChange={handleArrivalMethodChange}
							name="bySea"
						/>
					}
					label={t("MOYEN_ARRIVEE.maritime")}
				/>
			</div>
			<Typography sx={{ marginLeft: "1%", marginTop: "3%", fontWeight: "bold" }}>{t("CREATION_VISA.STEP2.precisionsDates")}</Typography>
			<div style={{ display: "flex" }}>
				<DatePickerPattern
					label={t("CREATION_VISA.STEP2.dateArrive")}
				/>
				<DatePickerPattern
					label={t("CREATION_VISA.STEP2.dateDepart")}
				/>
			</div>
			<Typography sx={{ marginLeft: "1%", marginTop: "3%", fontWeight: "bold" }}>{t("CREATION_VISA.STEP2.typeHebergement")}</Typography>
			<Grid container sx={{ marginTop: "1%" }}>
				<Grid item xs={6} md={3}>
					<FormControlLabel
						sx={{ marginLeft: "3%" }}
						control={
							<Checkbox
								checked={typeHousing.hotel}
								onChange={handleTypeHousing}
								name="hotel"
							/>
						}
						label={t("TYPE_HEBERGEMENT.hotel")}
					/></Grid>
				<Grid item xs={6} md={3}>
					<FormControlLabel
						sx={{ marginLeft: "3%" }}
						control={
							<Checkbox
								checked={typeHousing.camping}
								onChange={handleTypeHousing}
								name="camping"
							/>
						}
						label={t("TYPE_HEBERGEMENT.camping")}
					/></Grid>
				<Grid item xs={6} md={3}>
					<FormControlLabel
						sx={{ marginLeft: "3%" }}
						control={
							<Checkbox
								checked={typeHousing.habitant}
								onChange={handleTypeHousing}
								name="habitant"
							/>
						}
						label={t("TYPE_HEBERGEMENT.habitant")}
					/></Grid>
				<Grid item xs={6} md={3}>
					<FormControlLabel
						sx={{ marginLeft: "3%" }}
						control={
							<Checkbox
								checked={typeHousing.gite}
								onChange={handleTypeHousing}
								name="gite"
							/>
						}
						label={t("TYPE_HEBERGEMENT.gite")}
					/></Grid>
				<Grid item xs={6} md={3}>
					<FormControlLabel
						sx={{ marginLeft: "3%" }}
						control={
							<Checkbox
								checked={typeHousing.aubergeJeunesse}
								onChange={handleTypeHousing}
								name="aubergeJeunesse"
							/>
						}
						label={t("TYPE_HEBERGEMENT.aubergeJeunesse")}
					/>
				</Grid>
				<Grid item xs={6} md={3}>
					<FormControlLabel
						sx={{ marginLeft: "3%" }}
						control={
							<Checkbox
								checked={typeHousing.autre}
								onChange={handleTypeHousing}
								name="autre"
							/>
						}
						label={t("TYPE_HEBERGEMENT.autre")}
					/>
				</Grid>
			</Grid>
			<br />
			<TextFieldPattern label={t("CREATION_VISA.STEP2.lieuHabitation")} multipline={true} rowMultiline={4} />
			<TextFieldPattern
				label={t("CREATION_VISA.STEP2.contactTerritoire")}
			/>
		</div>
			:
			<div>
				<Typography sx={{ marginLeft: "1%", marginTop: "3%", fontWeight: "bold" }}>{t("CREATION_VISA.STEP2.moyenArrivee")}</Typography>
				<div style={{ margin: "1%" }}>
					<FormControlLabel
						control={
							<Checkbox
								checked={arrivalMethod.byLand}
								onChange={handleArrivalMethodChange}
								name="byLand"
							/>
						}
						label={t("MOYEN_ARRIVEE.terrestre")}
					/>
					<FormControlLabel
						sx={{ marginLeft: "3%" }}
						control={
							<Checkbox
								checked={arrivalMethod.byAir}
								onChange={handleArrivalMethodChange}
								name="byAir"
							/>
						}
						label={t("MOYEN_ARRIVEE.aerien")}
					/>
					<FormControlLabel
						sx={{ marginLeft: "3%" }}
						control={
							<Checkbox
								checked={arrivalMethod.bySea}
								onChange={handleArrivalMethodChange}
								name="bySea"
							/>
						}
						label={t("MOYEN_ARRIVEE.maritime")}
					/>
				</div>
				<SelectPattern
					label={t("CREATION_VISA.STEP2.raisonVoyage")}
					data={[
						{ value: "tourisme", label: t("RAISON_VOYAGE.tourisme") },
						{ value: "travail", label: t("RAISON_VOYAGE.travail") },
						{ value: "famille", label: t("RAISON_VOYAGE.famille") },
						{ value: "autre", label: t("RAISON_VOYAGE.autre") },
					]}
					onChange={(e) => {
						if (e.target.value === "autre") {
							setShowOtherReason(true);
						} else {
							setShowOtherReason(false);
						}
						setReason(e.target.value);
					}}
				/>
				{showOtherReason && (
					<TextFieldPattern
						label={t("CREATION_VISA.STEP2.precisionRaisonVoyage")}
					/>
				)}
				<Typography sx={{ marginLeft: "1%", marginTop: "3%", fontWeight: "bold" }}>{t("CREATION_VISA.STEP2.precisionsDates")}</Typography>
				<div style={{ display: "flex" }}>
					<DatePickerPattern
						label={t("CREATION_VISA.STEP2.dateArrive")}
					/>
					<DatePickerPattern
						label={t("CREATION_VISA.STEP2.dateDepart")}
					/>
				</div>
				<Typography sx={{ marginLeft: "1%", marginTop: "3%", fontWeight: "bold" }}>{t("CREATION_VISA.STEP2.typeHebergement")}</Typography>
				<Grid container sx={{ marginTop: "1%" }}>
					<Grid item xs={6} md={3}>
						<FormControlLabel
							sx={{ marginLeft: "3%" }}
							control={
								<Checkbox
									checked={typeHousing.hotel}
									onChange={handleTypeHousing}
									name="hotel"
								/>
							}
							label={t("TYPE_HEBERGEMENT.hotel")}
						/></Grid>
					<Grid item xs={6} md={3}>
						<FormControlLabel
							sx={{ marginLeft: "3%" }}
							control={
								<Checkbox
									checked={typeHousing.camping}
									onChange={handleTypeHousing}
									name="camping"
								/>
							}
							label={t("TYPE_HEBERGEMENT.camping")}
						/></Grid>
					<Grid item xs={6} md={3}>
						<FormControlLabel
							sx={{ marginLeft: "3%" }}
							control={
								<Checkbox
									checked={typeHousing.habitant}
									onChange={handleTypeHousing}
									name="habitant"
								/>
							}
							label={t("TYPE_HEBERGEMENT.habitant")}
						/></Grid>
					<Grid item xs={6} md={3}>
						<FormControlLabel
							sx={{ marginLeft: "3%" }}
							control={
								<Checkbox
									checked={typeHousing.gite}
									onChange={handleTypeHousing}
									name="gite"
								/>
							}
							label={t("TYPE_HEBERGEMENT.gite")}
						/></Grid>
					<Grid item xs={6} md={3}>
						<FormControlLabel
							sx={{ marginLeft: "3%" }}
							control={
								<Checkbox
									checked={typeHousing.aubergeJeunesse}
									onChange={handleTypeHousing}
									name="aubergeJeunesse"
								/>
							}
							label={t("TYPE_HEBERGEMENT.aubergeJeunesse")}
						/>
					</Grid>
					<Grid item xs={6} md={3}>
						<FormControlLabel
							sx={{ marginLeft: "3%" }}
							control={
								<Checkbox
									checked={typeHousing.autre}
									onChange={handleTypeHousing}
									name="autre"
								/>
							}
							label={t("TYPE_HEBERGEMENT.autre")}
						/>
					</Grid>
				</Grid>
				<br />
				<TextFieldPattern label={t("CREATION_VISA.STEP2.lieuHabitation")} multipline={true} rowMultiline={4} />
				<TextFieldPattern label={t("CREATION_VISA.STEP2.contactTerritoire")} />
			</div>
	);
}

export default Step2;
