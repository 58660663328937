import Typography from "@mui/material/Typography";
import { t } from "i18next";
import React, { useState } from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import LiensInternes from "../../services/liens";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import LoginIcon from '@mui/icons-material/Login';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";


interface IStep5 {
    isAnAgent: boolean;
    setActiveStep?: any;
}

function Step5({ isAnAgent, setActiveStep }: IStep5) {

    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const handleTerminerDemandeClick = () => {
        setOpen(true);
    };

    return (
        isAnAgent ?
            <div>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 40, marginTop: 60 }}>
                    <CheckCircleOutlineIcon color="success" sx={{ marginRight: 3, fontSize: '2rem' }} />
                    <Typography sx={{ margin: '5px auto' }}>{t("CREATION_VISA.STEP5.titleAgent")}</Typography>
                </div>
                <Box justifyContent="space-around" sx={{ display: "flex", flexDirection: "row", marginBottom: 15, marginTop: 5 }}>
                    <Button
                        startIcon={<AddCircleOutlineIcon />}
                        variant="contained"
                        size="medium"
                        onClick={() => {
                            navigate(LiensInternes.AGENT.BASE + LiensInternes.CREATION_VISA + LiensInternes.STEP_01)
                            setActiveStep(0);
                        }}
                    >
                        {t("CREATION_VISA.STEP5.boutonNouvelleDemande")}
                    </Button>
                    <Button
                        startIcon={<DoneOutlineIcon />}
                        variant="contained"
                        size="medium"
                        onClick={handleTerminerDemandeClick}
                    >
                        {t("CREATION_VISA.STEP5.boutonTerminerDemande")}
                    </Button>
                    <Dialog open={open}>
                        <DialogTitle sx={{ display: 'flex', alignItems: 'center'}}> <CheckCircleIcon sx={{marginRight: 1}} color="success" />{t("CREATION_VISA.STEP5.validationTitre")}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                               {t("CREATION_VISA.STEP5.validationContenu")}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => {
                                setOpen(false);
                                navigate(LiensInternes.AGENT.BASE + LiensInternes.AGENT.SUIVRE_DEMANDE);
                            }}>
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            </div> :
            <div>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 40, marginTop: 60 }}>
                    <CheckCircleOutlineIcon color="success" sx={{ marginRight: 3, fontSize: '2rem' }} />
                    <Typography sx={{ margin: '50px auto' }}>{t("CREATION_VISA.STEP5.title")}</Typography>
                </div>    
            </div>
    );
}

export default Step5;
