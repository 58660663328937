import Typography from "@mui/material/Typography";
import { t } from "i18next";
import React, { useState } from "react";
import DropDownComponent from "../../components/DropDownComponent/DropDownComponent";
import { grey } from '@mui/material/colors';
import Button from "@mui/material/Button";
import { Box, Card, CardContent, Checkbox, FormControlLabel, FormGroup, TextField } from "@mui/material";
import RadioPattern from "../../components/formPatterns/radioPattern/RadioPattern";

interface IStep4 {
    isAnAgent: boolean;
}

function Step4({ isAnAgent }: IStep4) {
    const [cardNumber, setCardNumber] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [cvv, setCvv] = useState('');
    const [paymentMethod, setPaymentMethod] = useState({
        CB: false,
        autre: false,
    });

    // const { register, handleSubmit, errors } = useForm();

    const handlePaiementMethodChange = (e: any) => {
        setPaymentMethod({
            CB: e.target.name === "CB" ? e.target.checked : false,
            autre: e.target.name === "autre" ? e.target.checked : false,
        });
    };

    const onSubmit = (data: any) => {
        console.log(data);
        alert('Paiement réussi!');
    };
    return (
        <> {isAnAgent ?
            <>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: 5 }}>
                    <Typography variant="h6" sx={{ marginTop: 1 }}>{t("CREATION_VISA.STEP4.typePaiement")}</Typography>
                    {/* <FormGroup row sx={{ marginLeft: 1, marginTop: 1, marginBottom: 4 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={paymentMethod.CB}
                                    onChange={handlePaiementMethodChange}
                                    name="CB"
                                />
                            }
                            label={t("CREATION_VISA.STEP4.CB")}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={paymentMethod.autre}
                                    onChange={handlePaiementMethodChange}
                                    name="autre"
                                />
                            }
                            label={t("CREATION_VISA.STEP4.autre")}
                        />
                    </FormGroup> */}
                    <Card>
                        <CardContent>
                            <Typography variant="h5" component="h1" gutterBottom>
                                {t("CREATION_VISA.STEP4.titrePaiementCB")}
                            </Typography>
                            <form onSubmit={onSubmit}>
                                <TextField
                                    type="tel"
                                    label={t("CREATION_VISA.STEP4.numeroCarte")}
                                    name="cardNumber"
                                    value={cardNumber}
                                    onChange={(e: any) => setCardNumber(e.target.value)}
                                    //   inputRef={register({
                                    //     required: true,
                                    //     pattern: {
                                    //       value: /^\d{16}$/,
                                    //       message: 'Entrez un numéro de carte valide'
                                    //     }
                                    //   })}
                                    fullWidth
                                //   error={Boolean(errors.cardNumber)}
                                //   helperText={
                                //     errors.cardNumber &&
                                //     errors.cardNumber.message
                                //   }
                                />
                                <TextField
                                    type="tel"
                                    label={t("CREATION_VISA.STEP4.dateExpiration")}
                                    name="expiryDate"
                                    value={expiryDate}
                                    onChange={(e) => setExpiryDate(e.target.value)}
                                    //   inputRef={register({
                                    //     required: true,
                                    //     pattern: {
                                    //       value: /^(0[1-9]|1[0-2])\/\d{2}$/,
                                    //       message: 'Entrez une date d\'expiration valide (MM/AA)'
                                    //     }
                                    //   })}
                                    fullWidth
                                    margin="normal"
                                //   error={Boolean(errors.expiryDate)}
                                //   helperText={
                                //     errors.expiryDate &&
                                //     errors.expiryDate.message
                                //   }
                                />
                                <TextField
                                    type="tel"
                                    label={t("CREATION_VISA.STEP4.CVV")}
                                    name="cvv"
                                    value={cvv}
                                    onChange={(e) => setCvv(e.target.value)}
                                    //   inputRef={register({
                                    //     required: true,
                                    //     pattern: {
                                    //       value: /^\d{3}$/,
                                    //       message: 'Entrez un CVV valide'
                                    //     }
                                    //   })}
                                    fullWidth
                                //   error={Boolean(errors.cvv)}
                                //   helperText={
                                //     errors.cvv &&
                                //     errors.cvv.message
                                //   }
                                />
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    //   disabled={Object.keys(errors).length > 0}
                                    fullWidth
                                    sx={{ marginTop: 2 }}
                                >
                                    {t("CREATION_VISA.STEP4.boutonPayer")}
                                </Button>
                            </form>
                        </CardContent>
                    </Card>
                </Box></> :
            <>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: 5 }}>
                    <Typography variant="h6" sx={{ marginTop: 1 }}>{t("CREATION_VISA.STEP4.typePaiement")}</Typography>
                    {/* <FormGroup row sx={{ marginLeft: 1, marginTop: 1, marginBottom: 4 }}>
                        <FormControlLabel
                            sx={{ marginRight: 10 }}
                            control={
                                <Checkbox
                                    checked={paymentMethod.CB}
                                    onChange={handlePaiementMethodChange}
                                    name="CB"
                                />
                            }
                            label={t("CREATION_VISA.STEP4.CB")}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={paymentMethod.autre}
                                    onChange={handlePaiementMethodChange}
                                    name="autre"
                                />
                            }
                            label={t("CREATION_VISA.STEP4.autre")}
                        />
                    </FormGroup> */}
                    <Card>
                        <CardContent>
                            <Typography variant="h5" component="h1" gutterBottom>
                                {t("CREATION_VISA.STEP4.titrePaiementCB")}
                            </Typography>
                            <form onSubmit={onSubmit}>
                                <TextField
                                    type="tel"
                                    label={t("CREATION_VISA.STEP4.numeroCarte")}
                                    name="cardNumber"
                                    value={cardNumber}
                                    onChange={(e: any) => setCardNumber(e.target.value)}
                                    //   inputRef={register({
                                    //     required: true,
                                    //     pattern: {
                                    //       value: /^\d{16}$/,
                                    //       message: 'Entrez un numéro de carte valide'
                                    //     }
                                    //   })}
                                    fullWidth
                                //   error={Boolean(errors.cardNumber)}
                                //   helperText={
                                //     errors.cardNumber &&
                                //     errors.cardNumber.message
                                //   }
                                />
                                <TextField
                                    type="tel"
                                    label={t("CREATION_VISA.STEP4.dateExpiration")}
                                    name="expiryDate"
                                    value={expiryDate}
                                    onChange={(e) => setExpiryDate(e.target.value)}
                                    //   inputRef={register({
                                    //     required: true,
                                    //     pattern: {
                                    //       value: /^(0[1-9]|1[0-2])\/\d{2}$/,
                                    //       message: 'Entrez une date d\'expiration valide (MM/AA)'
                                    //     }
                                    //   })}
                                    fullWidth
                                    margin="normal"
                                //   error={Boolean(errors.expiryDate)}
                                //   helperText={
                                //     errors.expiryDate &&
                                //     errors.expiryDate.message
                                //   }
                                />
                                <TextField
                                    type="tel"
                                    label={t("CREATION_VISA.STEP4.CVV")}
                                    name="cvv"
                                    value={cvv}
                                    onChange={(e) => setCvv(e.target.value)}
                                    //   inputRef={register({
                                    //     required: true,
                                    //     pattern: {
                                    //       value: /^\d{3}$/,
                                    //       message: 'Entrez un CVV valide'
                                    //     }
                                    //   })}
                                    fullWidth
                                //   error={Boolean(errors.cvv)}
                                //   helperText={
                                //     errors.cvv &&
                                //     errors.cvv.message
                                //   }
                                />
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    //   disabled={Object.keys(errors).length > 0}
                                    fullWidth
                                    sx={{ display: "flex",  margin: '0 auto', maxWidth: '20%', marginTop: 2 }}
                                >
                                    {t("CREATION_VISA.STEP4.boutonPayer")}
                                </Button>
                            </form>
                        </CardContent>
                    </Card>
                </Box></>}</>
    );
}

export default Step4;
