import { Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import DetailDemandeComponent from "../../../components/DetailDemandeComponent/DetailDemandeComponent";

interface IDetailsDemande {
	role: "client" | "agent" | "dst" | "ministere" | "paf";
}

function DetailsDemande({ role }: IDetailsDemande) {
	let { id } = useParams();

	const { t } = useTranslation();
	return (
		<div>
			{" "}
			<Typography
				variant="h3"
				component="div"
				sx={{
					flexGrow: 1,
				}}
			>
				{t("USER_FINAL.detailDemande.title")}
			</Typography>
			<DetailDemandeComponent role={role} id={id ? parseInt(id) : 0} />
		</div>
	);
}

export default DetailsDemande;
