const LiensInternes = {
	AGENT: {
		BASE: "/agent",
		DASHBOARD: "/dashboard",
		NEW_DEMANDE: "/demande-agent",
		SUIVRE_DEMANDE: "/suivre-demande-agent",
	},
	DST: {
		BASE: "/dst",
		DASHBOARD: "/dashboard",
		CATEGORIE_B: "/categorie-b",
		CATEGORIE_C: "/categorie-c",
		TOUTES_CATEGORIES: "/toutes-categories",
		AFFECTER_AGENT: "/affecter-agent",
	},
	MINISTERE: {
		SUPERVISEUR_BASE: "/ministereSuperviseur",
		AGENT_BASE: "/ministereAgent",
		DASHBOARD: "/dashboard",
		CATEGORIE_B: "/categorie-b",
		CATEGORIE_C: "/categorie-c",
		TOUTES_CATEGORIES: "/toutes-categories",
	},
	PAF: {
		BASE: "/paf",
		DASHBOARD: "/dashboard",
		ARRIVEE_JOUR: "/arrivees-du-jour",
		TOUTES_DEMANDES: "/liste-demandes",
	},
	USER: {
		BASE: "/utilisateur",
		DASHBOARD: "/dashboard",
		SUIVRE_DEMANDE: "/suivre-demande",
		NOUVELLE_DEMANDE: "/nouvelle-demande-visa"
	},
	CONNEXION: "/login",
	ACCEUIL: "/home",
	CREATION_VISA: "/visa-creation",
	STEP_00: "/creation-compte",
	STEP_01: "/informations-personnelles",
	STEP_02: "/informations-voyage",
	STEP_03: "/transfert-documents",
	STEP_04: "/paiement",
	STEP_05: "/validation-demande",
	DETAIL_GROUP: "/details-group",
	DETAIL_DEMANDE: "/details-demande",
	MESSAGERIE: "/messagerie",
};

export default LiensInternes;
