import { Container, Grid, Typography } from "@mui/material";
import React from "react";
import "./Home.scss";
import { useTranslation } from "react-i18next";
import CardHome from "../../components/Card/CardHome/CardHome";
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import Filter1Icon from '@mui/icons-material/Filter1';
import Filter2Icon from '@mui/icons-material/Filter2';
import Filter3Icon from '@mui/icons-material/Filter3';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import QuizIcon from '@mui/icons-material/Quiz';
import SliderHome from "./SliderHome";

function Home() {
	const { t } = useTranslation();

	return (
		<div>
			<SliderHome />
			<Container maxWidth="xl">
				<Typography
					align="center"
					variant="h2"
					component="div"
					sx={{ flexGrow: 1, mt: 5, mb: 2 }}
				>
					{t("HOME.titre.informations")}
				</Typography>

				<Grid
					container
					rowSpacing={{ xs: 1, sm: 2, md: 3, lg: 5 }}
					columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 5 }}
					sx={{
						p: 5
					}}
				>
					<Grid item xs={6}>
						<div className="texte-description">
							<CardHome colorBackground={"brown"} image={<AssignmentLateIcon fontSize="large"/>} title="IMPORTANT : Les détails de validations peuvent dépasser 48 heures ouvrables." description={t("HOME.description1")} colorTexteTitle={"#CD2222"}/>
						</div>
					</Grid>
					<Grid item xs={6}>
						<div className="texte-description">
							<CardHome colorBackground={"orange"} image={<FileOpenIcon fontSize="large"/>} title="Documents à envoyer pour une demande en ligne" description={t("HOME.description2")}/>
						</div>
					</Grid>
					<Grid item xs={6}>
						<div className="texte-description">
							<CardHome colorBackground={"green"} image={<Filter1Icon fontSize="large"/>} title="Étape 1 : Demande de visa en ligne" description={t("HOME.description3")}/>
						</div>
					</Grid>
					<Grid item xs={6}>
						<div className="texte-description">
							<CardHome colorBackground={"green"} image={<Filter2Icon fontSize="large"/>} title="Étape 2 : Réception de la réponse du Département des Services de l'Immigration et des Titres de Séjour" description={t("HOME.description4")}/>
						</div>
					</Grid>
					<Grid item xs={6}>
						<div className="texte-description">
							<CardHome colorBackground={"green"} image={<Filter3Icon fontSize="large"/>} title="Étape 3 : Vérification lors du passage aux frontières" description={t("HOME.description5")}/>
						</div>
					</Grid>
					<Grid item xs={6}>
						<div className="texte-description">
							<CardHome colorBackground={"blue"} image={<QuizIcon fontSize="large"/>} title="Contactez-nous à tout moment en cas de difficulté !" description={t("HOME.description6")} colorTexteTitle={"#420BD1"}/>
						</div>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
}

export default Home;
