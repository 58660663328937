import React, { useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { Autocomplete, Box, Chip, Grid, TextField } from "@mui/material";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import "./SuivreDemandesAgent.scss";
import StepperAvancement from "../../../components/StepperAvancement/StepperAvancement";
import TablePattern from "../../../components/Table/TablePattern";
import LiensInternes from "../../../services/liens";
import { useTranslation } from "react-i18next";
import TableAgentGroup from "../../../components/TableAgentGroup/TableAgentGroup";
import PaginationPattern from "../../../components/Pagination";

function SuivreDemandesAgent() {
	const { t } = useTranslation();
	const listePersonne = [
		{
			nom: "Julien",
			prenom: "Trésol",
			numeroPassport: "0678654782165886",
			status: "En préparation",
			progress: 30,
		},
		{
			nom: "Amélie",
			prenom: "Dubois",
			numeroPassport: "0678654782165886",
			status: "Accepté",
			progress: 100,
		},
		{
			nom: "Paul",
			prenom: "Langlois",
			numeroPassport: "0678654782165886",
			status: "Refusé",
			progress: 100,
		},
	];
	const options = ['Reel it Fev 2023', 'CMA CGM Janv 2023', 'Hcube Janv 2023', 'Reel it Nov 2022'];
	const [searchValue, setSearchValue] = useState<string | null>(null);
	return (
		<>
			<Typography
				variant="h3"
				component="div"
				sx={{
					flexGrow: 1,
				}}
			>
				{t("AGENT.suiviDemandeAgent.title")}
			</Typography>
			<Autocomplete
				options={options}
				value={searchValue}
				onChange={(event, newValue) => {
					setSearchValue(newValue);
				}}
				renderInput={(params) => (
					<TextField
						{...params}
						label="Rechercher une demande"
						variant="outlined"
					/>
				)}
				sx={{
					marginTop: 5
				}}
			/>
			<TableAgentGroup
				data={[
					{
						id: 1456737678,
						date: "10-10-2023",
						step: "En préparation",
						new: false,
						group: "Reel it Fev 2023",
						nbPersonne: 2,
					},
					{
						id: 5546567001,
						date: "10-10-2023",
						step: "En préparation",
						new: false,
						group: "CMA CGM Janv 2023",
						nbPersonne: 2,
					},
					{
						id: 627965,
						date: "10-10-2023",
						step: "En préparation",
						new: false,
						group: "Hcube Janv 2023",
						nbPersonne: 2,
					},
					{
						id: 330157705,
						date: "10-10-2023",
						step: "En préparation",
						new: false,
						group: "Reel it Nov 2022",
						nbPersonne: 2,
					},
					{
						id: 702189156005,
						date: "10-10-2023",
						step: "Terminée",
						new: false,
						group: "CMA CGM Oct 2022",
						nbPersonne: 2,
					},
					{
						id: 24249241543,
						date: "10-10-2023",
						step: "Terminée",
						new: false,
						group: "Reel it Juil 2022",
						nbPersonne: 2,
					},
					{
						id: 82520,
						date: "10-10-2023",
						step: "Terminée",
						new: false,
						group: "Reel it Nov 2022",
						nbPersonne: 2,
					},
					{ id: 325201710, date: "10-10-2023", step: "Terminée", new: false, group: "Hcube Juin 2022", nbPersonne: 2 },
					{
						id: 7891485,
						date: "10-10-2023",
						step: "Terminée",
						new: false,
						group: "CMA CGM Oct 2022",
						nbPersonne: 2,
					},
					{
						id: 320215688,
						date: "10-10-2023",
						step: "Terminée",
						new: false,
						group: "CMA CGM Oct 2022",
						nbPersonne: 2,
					},
					{ id: 3478902251, date: "10-10-2023", step: "Terminée", new: false, group: "Hcube Juin 2022", nbPersonne: 2 },
					{ id: 94560266, date: "10-10-2023", step: "Terminée", new: false, group: "Hcube Juin 2022", nbPersonne: 2 },
				]}
				buttonVoirTout={false}
				btnVoirPlusUrl={
					LiensInternes.AGENT.BASE + LiensInternes.AGENT.SUIVRE_DEMANDE + LiensInternes.DETAIL_GROUP
				}
			/>
			<PaginationPattern/>
		</>
	);
}

export default SuivreDemandesAgent;
