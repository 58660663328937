import { Box } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Navigate, Routes, Route } from "react-router-dom";
import DashboardNavBar from "../../components/navbar/DashboardNavBar/DashboardNavBar";
import LiensInternes from "../../services/liens";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import DashboardMinistere from "./DashboardMinistere/DashboardMinistereAgent";
import DetailsDemande from "../UserFinal/DetailsDemande/DetailsDemande";
import DemandesAttenteAcceptationAgentCategorieC from "./DemandesAttenteAcceptation/DemandesAttenteAcceptationAgentCategorieC";
import DemandesAttenteAcceptationAgentCategorieB from "./DemandesAttenteAcceptation/DemandesAttenteAcceptationAgentCategorieB";
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';

const drawerWidth = 240;

function LayoutMinistereAgent() {
	const { t } = useTranslation();

	const listItem = [
		{
			nom: t("Tableau de bord"),
			url: LiensInternes.MINISTERE.AGENT_BASE + LiensInternes.MINISTERE.DASHBOARD,
			picto: <InboxIcon />,
		},
		{
			nom: "Voir les demandes en catégorie C",
			url: LiensInternes.MINISTERE.AGENT_BASE + LiensInternes.MINISTERE.CATEGORIE_C,
			picto: <MailIcon />,
		},
		{
			nom: "Voir les demandes en catégorie B",
			url: LiensInternes.MINISTERE.AGENT_BASE + LiensInternes.MINISTERE.CATEGORIE_B,
			picto: <MailIcon />,
		},
		{
			nom: "Voir toutes les demandes",
			url: LiensInternes.MINISTERE.SUPERVISEUR_BASE + LiensInternes.MINISTERE.TOUTES_CATEGORIES,
			picto: <MailIcon />,
		},
		{
			nom: "Messagerie",
			url: LiensInternes.MINISTERE.AGENT_BASE + LiensInternes.MESSAGERIE,
			picto: <MarkUnreadChatAltIcon />,
		},
	];

	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "space-between",
				width: "100%",
				position: "relative",
				zIndex: 1
			}}
		>
			<DashboardNavBar listItem={listItem} />
			<Box
				component="main"
				className="layout-dashboard"
				sx={{
					width: { sm: `calc(100% - ${drawerWidth}px)` },
				}}
			>
				<Routes>
					<Route
						path="/"
						element={<Navigate to={LiensInternes.MINISTERE.AGENT_BASE + LiensInternes.MINISTERE.DASHBOARD} />}
					/>
					<Route path={LiensInternes.MINISTERE.DASHBOARD} element={<DashboardMinistere />} />
					<Route path={LiensInternes.MINISTERE.CATEGORIE_C} element={<DemandesAttenteAcceptationAgentCategorieC />} />
					<Route path={LiensInternes.MINISTERE.CATEGORIE_B} element={<DemandesAttenteAcceptationAgentCategorieB />} />
					<Route path={LiensInternes.MINISTERE.AGENT_BASE + LiensInternes.MESSAGERIE} element={<DemandesAttenteAcceptationAgentCategorieB />} />
					<Route
						path={LiensInternes.DETAIL_DEMANDE + "/:id"}
						element={<DetailsDemande role={"ministere"} />}
					/>
				</Routes>
			</Box>
		</Box>
	);
}

export default LayoutMinistereAgent;
