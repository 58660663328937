import { useState } from 'react';
import Pagination from '@mui/material/Pagination';

function PaginationPattern() {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = 10; // Nombre total de pages

  const handleChange = (value:any) => {
    setCurrentPage(value);
  };

  return (
    <div>
      <Pagination count={totalPages} page={currentPage} onChange={handleChange} sx={{display: "flex", justifyContent: "center", marginBottom:2}}/>
    </div>
  );
}

export default PaginationPattern;
