import { TRUE } from "sass";
import { TYPES } from "../actions/actionTypes";
import { IUser, UserAction, UserState } from "../Interfaces/typeUser";

const initialState: UserState =
  [
    {
      id: 1,
      role: "user",
      request_date: "12/12/2022",
      arrival_date: "18/12/2022",
      departure_date: "30/12/2022",
      durationStay: "12",
      step: 2,
      new: true,
      category: "B",
      user: {
        id: 1,
        nom: "Vilma",
        prenom: "Bastien",
        dateNaissance: "10/10/1988",
        pays: "France",
        nationalite: "Français",
        telephone: "06.58.13.65.47",
        email: "b.vilma@gmail.com",
        adresse: "16 rue de Napoléon 13000 Marseille",
        profession: "Développeur",
        numeroPassport: "94849623587456598451265984",
      },
      nomAgentDst: "John Doe",
      nomAgentMinistere: "Marielle Provo",
      message: 3
    },
    {
      id: 2,
      role: "marin",
      request_date: "11/12/2022",
      arrival_date: "18/12/2022",
      departure_date: "28/12/2022",
      durationStay: "10",
      step: 1,
      new: false,
      category: "B",
      user: {
        id: 1,
        nom: "Vilma",
        prenom: "Bastien",
        dateNaissance: "10/10/1988",
        pays: "France",
        nationalite: "Français",
        telephone: "06.58.13.65.47",
        email: "b.vilma@gmail.com",
        adresse: "16 rue de Napoléon 13000 Marseille",
        profession: "Développeur",
        numeroPassport: "94849623587456598451265984",
      },
      nomAgentDst: "John Doe",
      nomAgentMinistere: "Marielle Provo",
      message: 1
    },
    {
      id: 3,
      role: "user",
      request_date: "10/10/2022",
      arrival_date: "17/12/2022",
      departure_date: "24/12/2022",
      durationStay: "7",
      step: 2,
      new: false,
      category: "C",
      user: {
        id: 1,
        nom: "Vilma",
        prenom: "Bastien",
        dateNaissance: "10/10/1988",
        pays: "France",
        nationalite: "Français",
        telephone: "06.58.13.65.47",
        email: "b.vilma@gmail.com",
        adresse: "16 rue de Napoléon 13000 Marseille",
        profession: "Développeur",
        numeroPassport: "94849623587456598451265984",
      },
      nomAgentDst: "",
      nomAgentMinistere: "Marielle Provo",
      message: 0
    },
    {
      id: 4,
      role: "marin",
      request_date: "10/10/2022",
      arrival_date: "16/12/2022",
      departure_date: "24/12/2022",
      durationStay: "8",
      step: 3,
      new: false,
      category: "C",
      user: {
        id: 1,
        nom: "Vilma",
        prenom: "Bastien",
        dateNaissance: "10/10/1988",
        pays: "France",
        nationalite: "Français",
        telephone: "06.58.13.65.47",
        email: "b.vilma@gmail.com",
        adresse: "16 rue de Napoléon 13000 Marseille",
        profession: "Développeur",
        numeroPassport: "94849623587456598451265984",
      },
      nomAgentDst: "John Doe",
      nomAgentMinistere: "Marielle Provo",
      message: 0
    },
    {
      id: 4,
      role: "marin",
      request_date: "10/10/2022",
      arrival_date: "16/12/2022",
      departure_date: "23/12/2022",
      durationStay: "7",
      step: 3,
      new: true,
      category: "C",
      user: {
        id: 1,
        nom: "Vilma",
        prenom: "Bastien",
        dateNaissance: "10/10/1988",
        pays: "France",
        nationalite: "Français",
        telephone: "06.58.13.65.47",
        email: "b.vilma@gmail.com",
        adresse: "16 rue de Napoléon 13000 Marseille",
        profession: "Développeur",
        numeroPassport: "94849623587456598451265984",
      },
      nomAgentDst: "",
      nomAgentMinistere: "Marielle Provo",
      message: 1
    }
  ]


const userReducer = (
  state: UserState = initialState,
  action: UserAction
): UserState => {
  switch (action.type) {
    // case TYPES.USER.ADD:
    //   const newUser: IUser = {
    //     id: Math.random(), // not really unique
    //     email: action.user.email,
    //     password: action.user.password,
    //   }
    //   return {
    //     ...state,
    //     user: state.user.concat(newUser),
    //   }
    // case TYPES.USER.REMOVE:
    //   const removeUser: IUser[] = state.filter(
    //     usr => usr.id !== action.user.id
    //   )
    //   return {
    //     removeUser
    //   }
  }
  return state
}

export default userReducer
