import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import TablePattern from "../../../components/Table/TablePattern";
import { IUser } from "../../../redux/Interfaces/typeUser";
import { IRedux } from "../../../redux/reducers";
import LiensInternes from "../../../services/liens";

function DemandesAttenteAcceptationAgentCategorieB() {
	const { t } = useTranslation();

	const user = useSelector((state: IRedux) => state.user);

	return (
		<div>
			<Typography
				variant="h3"
				component="div"
				sx={{
					flexGrow: 1,
				}}
			>
				{t("MINISTERE.enAttenteValidation.titleCategorieB")}
			</Typography>

			<TablePattern
				data={user.filter((u: IUser) => u.category === "B")}
				buttonVoirTout={false}
				btnVoirPlusUrl={LiensInternes.MINISTERE.AGENT_BASE + LiensInternes.DETAIL_DEMANDE}
				role={"ministere"}
			/>
		</div>
	);
}

export default DemandesAttenteAcceptationAgentCategorieB;
