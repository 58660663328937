import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import TablePattern from "../../../components/Table/TablePattern";
import { IRedux } from "../../../redux/reducers";
import LiensInternes from "../../../services/liens";

function DetailsGroup() {
	const { t } = useTranslation();
	const marin = useSelector((state: IRedux) => state.marin);
	return (
		<div>
			<Typography
				variant="h3"
				component="div"
				sx={{
					flexGrow: 1,
				}}
			>
				{t("USER_FINAL.toutesDemandes.title")}
			</Typography>
			<TablePattern
				role="agent"
				data={marin}
				buttonVoirTout={false}
				btnVoirPlusUrl={LiensInternes.AGENT.BASE + LiensInternes.DETAIL_DEMANDE}
				btnMessagerieUrl={LiensInternes.AGENT.BASE + LiensInternes.MESSAGERIE}
			/>
		</div>
	);
}

export default DetailsGroup;
