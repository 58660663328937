import { Box, Button, Container, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import TextFieldPattern from "../../components/formPatterns/textFieldPattern/TextFieldPattern";
import LiensInternes from "../../services/liens";
import "./Connexion.scss";

function ButtonRole({ label, navigate }: { label: string, navigate: string }) {
    const { t } = useTranslation();
    const history = useNavigate();

    return (
        <Button style={{margin: 10}} onClick={() => history(navigate)} variant="contained">
            {t(label)}
        </Button>
    );
}

function Connexion() {
	const { t } = useTranslation();
    return (
        <Container maxWidth="xl" className="container-main">
            <Box className="box-connexion">
                <Typography
                    align="center"
                    variant="h2"
                    component="div"
                    sx={{
                        flexGrow: 1,
                    }}
                >
                    {t("CONNEXION.title")}
                </Typography>
                <TextFieldPattern label={t("CONNEXION.email")} />
                <TextFieldPattern label={t("CONNEXION.password")} />
                <ButtonRole label="CONNEXION.agent" navigate={LiensInternes.AGENT.BASE} />
                <ButtonRole label="CONNEXION.userFinal" navigate={LiensInternes.USER.BASE} />
                <ButtonRole label="CONNEXION.dst" navigate={LiensInternes.DST.BASE} />
                <ButtonRole label="CONNEXION.minstereSuperviseur" navigate={LiensInternes.MINISTERE.SUPERVISEUR_BASE} />
                <ButtonRole label="CONNEXION.minstereAgent" navigate={LiensInternes.MINISTERE.AGENT_BASE}/>
                <ButtonRole label="CONNEXION.paf" navigate={LiensInternes.PAF.BASE} />
            </Box>
        </Container>
    );
}

export default Connexion;