import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Avatar, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Typography } from "@mui/material";
import "../Table/TablePattern.scss";
import { useTranslation } from "react-i18next";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { useNavigate } from "react-router-dom";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import LiensInternes from "../../services/liens";

interface ITableAgentGroup {
	data: {
		id: number;
		date: string;
		step: string;
		new: boolean;
		group: string;
		nbPersonne: number;
	}[];
	buttonVoirTout: boolean;
	btnNavigateUrl?: string;
	btnVoirPlusUrl: string;
}

function TableAgentGroup({ data, buttonVoirTout, btnNavigateUrl, btnVoirPlusUrl }: ITableAgentGroup) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [openModaleAddPeople, setOpenModaleAddPeople] = useState(false);
	const [openModaleTransfer, setOpenModaleTransfer] = useState(false);

	return (
		<div className="container-table">
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					{buttonVoirTout && (
						<caption>
							<Button variant="contained" onClick={() => btnNavigateUrl && navigate(btnNavigateUrl)}>
								{t("USER_FINAL.table.voirPlus")}
							</Button>
						</caption>
					)}
					<TableHead>
						<TableRow>
							<TableCell align="center">{t("LISTE.date")}</TableCell>
							<TableCell align="center">{t("LISTE.idDemande")}</TableCell>
							<TableCell align="center">{t("LISTE.group")}</TableCell>
							<TableCell align="center">{t("LISTE.nbPersonne")}</TableCell>
							<TableCell align="center">{t("LISTE.etape")}</TableCell>
							<TableCell align="center">{t("LISTE.actions")}</TableCell>
							<TableCell align="center">{t("LISTE.voirPLus")}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{data.map((row) => (
							<TableRow
								key={row.id}
								sx={{
									"&:last-child td, &:last-child th": {
										border: 0,
									},
									opacity: row.step === "Terminée" ? 0.5 : 1,
								}}
							>
								<TableCell component="th" scope="row" align="center">
									<div style={{ display: "flex", justifyContent: "center" }}>
										{row.new && <NewReleasesIcon color="error" sx={{ marginRight: "0.7rem" }} />}
										<Typography
											variant="body1"
											component="div"
											sx={{
												flexGrow: 1,
												display: "inline-block",
											}}
										>
											{row.date}
										</Typography>
									</div>
								</TableCell>
								<TableCell align="center">{row.id}</TableCell>
								<TableCell align="center">{row.group}</TableCell>
								<TableCell align="center">
									{t("LISTE.nbPersonne")} : {row.nbPersonne}
								</TableCell>
								<TableCell align="center">{row.step}</TableCell>
								<TableCell align="center">
									<Button onClick={() => setOpenModaleAddPeople(true)}>
										<PersonAddAltIcon />
									</Button>
									<Button onClick={() => setOpenModaleTransfer(true)}>
										<CheckCircleOutlineIcon />
									</Button>
								</TableCell>
								<Dialog open={openModaleAddPeople} onClose={() => setOpenModaleAddPeople(false)}>
									<DialogTitle sx={{ display: 'flex', alignItems: 'center' }}> <PersonAddAltIcon sx={{ marginRight: 2 }} />{t("MODALES_DASHBOARD_AGENT.titreAddPeople")}</DialogTitle>
									<DialogContent>
										<DialogContentText>
											{t("MODALES_DASHBOARD_AGENT.descriptionAddPeople")}
										</DialogContentText>
									</DialogContent>
									<DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
										<Button onClick={() => {
											setOpenModaleAddPeople(false);
										}}>
											Annuler
										</Button>
										<Button onClick={() => {
											setOpenModaleAddPeople(false);
											navigate(LiensInternes.AGENT.BASE + LiensInternes.CREATION_VISA + LiensInternes.STEP_01);
										}}>
											Ajouter
										</Button>
									</DialogActions>
								</Dialog>
								<Dialog open={openModaleTransfer} onClose={() => setOpenModaleTransfer(false)}>
									<DialogTitle sx={{ display: 'flex', alignItems: 'center' }}> <CheckCircleOutlineIcon sx={{ marginRight: 1 }} color="info" />{t("MODALES_DASHBOARD_AGENT.titreTransfer")}</DialogTitle>
									<DialogContent>
										<DialogContentText>
											{t("MODALES_DASHBOARD_AGENT.descriptionTransfer")}
										</DialogContentText>
									</DialogContent>
									<DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
										<Button onClick={() => {
											setOpenModaleTransfer(false);
										}}>
											Annuler
										</Button>
										<Button onClick={() => {
											setOpenModaleTransfer(false);
											navigate(LiensInternes.AGENT.BASE + LiensInternes.AGENT.SUIVRE_DEMANDE);
										}}>
											Valider
										</Button>
									</DialogActions>
								</Dialog>
								<TableCell align="center">
									<Button onClick={() => navigate(btnVoirPlusUrl)}>
										<AddCircleOutlineIcon />
									</Button>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
}

export default TableAgentGroup;
