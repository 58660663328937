import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { useTranslation } from "react-i18next";
import "./services/traduction/i18n";
import { Navigate, Route, Routes } from "react-router-dom";
import Home from "./pages/home/Home";
import Footer from "./components/navbar/Footer/Footer";
import CreationVisa from "./pages/CreationVisa/CreationVisa";
import HeaderNavBar from "./components/navbar/HeaderNavBar";
import LiensInternes from "./services/liens";
import Connexion from "./pages/Connexion/Connexion";
import DashboardAgent from "./pages/Agent/DashboardAgent";
import LayoutUserFinal from "./pages/UserFinal/LayoutUserFinal";
import LayoutDst from "./pages/Dst/LayoutDst";
import LayoutMinistere from "./pages/Ministere/LayoutMinistere";
import LayoutPaf from "./pages/Paf/LayoutPaf";
import LayoutMinistereAgent from "./pages/Ministere copy/LayoutMinistereAgent";
import LayoutMessagerie from "./components/Messagerie/LayoutMessagerie";
import Step0 from "./pages/CreationVisa/Step0";

function App() {
	return (
		<>
			<HeaderNavBar />
			<Routes>
				<Route path="/" element={<Navigate to={LiensInternes.ACCEUIL} />} />
				<Route path={LiensInternes.ACCEUIL} element={<Home />} />
				<Route path={LiensInternes.CREATION_VISA + LiensInternes.STEP_00} element={<Step0 />} />
				<Route path={LiensInternes.CREATION_VISA + "/*"} element={<CreationVisa />} />
				<Route path={LiensInternes.CONNEXION} element={<Connexion />} />
				{/* EN CONNEXION  */}
				<Route path={LiensInternes.AGENT.BASE + "/*"} element={<DashboardAgent />} />
				<Route path={LiensInternes.AGENT.BASE + LiensInternes.CREATION_VISA + "/*"} element={<CreationVisa />} />
				<Route path={LiensInternes.USER.BASE + "/*"} element={<LayoutUserFinal />} />
				<Route path={LiensInternes.USER.BASE + "/messagerie"} element={<LayoutMessagerie />} />
				<Route path={LiensInternes.AGENT.BASE + "/messagerie"} element={<LayoutMessagerie />} />
				<Route path={LiensInternes.DST.BASE + "/messagerie"} element={<LayoutMessagerie />} />
				<Route path={LiensInternes.MINISTERE.SUPERVISEUR_BASE + "/messagerie"} element={<LayoutMessagerie />} />
				<Route path={LiensInternes.MINISTERE.AGENT_BASE + "/messagerie"} element={<LayoutMessagerie />} />
				<Route path={LiensInternes.DST.BASE + "/*"} element={<LayoutDst />} />
				<Route path={LiensInternes.MINISTERE.SUPERVISEUR_BASE + "/*"} element={<LayoutMinistere />} />
				<Route path={LiensInternes.MINISTERE.AGENT_BASE + "/*"} element={<LayoutMinistereAgent />} />
				<Route path={LiensInternes.PAF.BASE + "/*"} element={<LayoutPaf />} />
			</Routes>

			<Footer />
		</>
	);
}

export default App;
