import { Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import DoughnutPattern from "../../../components/Statistiques/DoughnutPattern";
import TablePattern from "../../../components/Table/TablePattern";
import LiensInternes from "../../../services/liens";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import LineChartPattern from "../../../components/Statistiques/LineChartPattern";
import { useDispatch, useSelector } from "react-redux";
import { IRedux } from "../../../redux/reducers";
import { IUser } from "../../../redux/Interfaces/typeUser";
import BarChartPattern from "../../../components/Statistiques/BarChartPattern";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	margin: "0.5rem",
	marginTop: "3rem",
	color: theme.palette.text.secondary,
}));

function DashboardDst() {
	const { t } = useTranslation();

	const user = useSelector((state: IRedux) => state.user);

	const userEnCours = user.filter((u: IUser) => u.step === 2);
	const userEnCoursC = userEnCours.filter((u: IUser) => u.category === "C");
	const userEnCoursB = userEnCours.filter((u: IUser) => u.category === "B");

	return (
		<div>
			<Typography
				variant="h3"
				component="div"
				sx={{
					flexGrow: 1,
				}}
			>
				{t("DST.dashboard.title")}
			</Typography>

			<Grid container spacing={2}>
				<Grid xs={8}>
					<Item>
						<Typography
							variant="h6"
							component="div"
							sx={{
								flexGrow: 1,
							}}
						>
							{t("DST.dashboard.nombreDemandeJour")}
						</Typography>
						<BarChartPattern />
					</Item>
				</Grid>
				<Grid xs={4}>
					<Item>
						<Typography
							variant="h6"
							component="div"
							sx={{
								flexGrow: 1,
							}}
						>
							{t("DST.dashboard.demandesParAgent")}
						</Typography>
						<DoughnutPattern />
					</Item>
				</Grid>
			</Grid>

			<Typography
				variant="h6"
				component="div"
				sx={{
					flexGrow: 1,
					margin: "2rem",
				}}
			>
				{t("DST.dashboard.titleC")}
			</Typography>

			<TablePattern
				role="autorite"
				data={userEnCoursC}
				buttonVoirTout={true}
				btnNavigateUrl={LiensInternes.DST.BASE + LiensInternes.DST.CATEGORIE_C}
				btnVoirPlusUrl={LiensInternes.DST.BASE + LiensInternes.DETAIL_DEMANDE}
			/>

			<Typography
				variant="h6"
				component="div"
				sx={{
					flexGrow: 1,
					margin: "2rem",
				}}
			>
				{t("DST.dashboard.titleB")}
			</Typography>

			<TablePattern
				role="autorite"
				data={userEnCoursB}
				buttonVoirTout={true}
				btnNavigateUrl={LiensInternes.DST.BASE + LiensInternes.DST.CATEGORIE_B}
				btnVoirPlusUrl={LiensInternes.DST.BASE + LiensInternes.DETAIL_DEMANDE}
			/>
		</div>
	);
}

export default DashboardDst;
