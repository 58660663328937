import { Box, Container, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import DashboardNavBar from "../../components/navbar/DashboardNavBar/DashboardNavBar";
import "./LayoutUserFinal.scss";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import PostAddIcon from '@mui/icons-material/PostAdd';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import LiensInternes from "../../services/liens";
import { Navigate, Route, Routes } from "react-router-dom";
import DashboardUserFinal from "./DashboardUserFinal/DashboardUserFinal";
import ToutesDemandes from "./ToutesDemandes/ToutesDemandes";
import DetailsDemande from "./DetailsDemande/DetailsDemande";
import LayoutMessagerie from "../../components/Messagerie/LayoutMessagerie";
import NouvelleDemande from "./NouvelleDemande/NouvelleDemande";

const drawerWidth = 240;

function LayoutUserFinal() {
	const { t } = useTranslation();

	const listItem = [
		{
			nom: t("Tableau de bord"),
			url: LiensInternes.USER.BASE + LiensInternes.USER.DASHBOARD,
			picto: <InboxIcon />,
		},
		{
			nom: "Voir toutes mes demandes",
			url: LiensInternes.USER.BASE + LiensInternes.USER.SUIVRE_DEMANDE,
			picto: <MailIcon />,
		},
		{
			nom: "Nouvelle demande de visa",
			url: LiensInternes.USER.BASE + LiensInternes.USER.NOUVELLE_DEMANDE,
			picto: <PostAddIcon />,
		},
		{
			nom: "Messagerie",
			url: LiensInternes.USER.BASE + LiensInternes.MESSAGERIE,
			picto: <MarkUnreadChatAltIcon />,
		},
	];

	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "space-between",
				width: "100%",
				position: "relative",
				zIndex: 1
			}}
		>
			<DashboardNavBar listItem={listItem} />
			<Box
				component="main"
				className="layout-dashboard"
				sx={{
					width: { sm: `calc(100% - ${drawerWidth}px)` },
				}}
			>
				<Routes>
					<Route
						path="/"
						element={<Navigate to={LiensInternes.USER.BASE + LiensInternes.USER.DASHBOARD} />}
					/>
					<Route path={LiensInternes.USER.DASHBOARD} element={<DashboardUserFinal />} />
					<Route
						path={LiensInternes.USER.SUIVRE_DEMANDE + "/:id"}
						element={<DetailsDemande role={"client"} />}
					/>
					<Route path={LiensInternes.USER.BASE + "/messagerie"} element={<LayoutMessagerie />} />
					<Route path={LiensInternes.USER.SUIVRE_DEMANDE} element={<ToutesDemandes />} />
					<Route path={LiensInternes.USER.NOUVELLE_DEMANDE} element={<NouvelleDemande />} />
				</Routes>
			</Box>
		</Box>
	);
}

export default LayoutUserFinal;
