import FormControl from "@mui/material/FormControl/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import FormLabel from "@mui/material/FormLabel/FormLabel";
import Radio from "@mui/material/Radio/Radio";
import RadioGroup from "@mui/material/RadioGroup/RadioGroup";
import React from "react";

interface IRadioPatternProps {
	label: string;
	row: boolean;
	defaultChecked?: string;
	labelPlacement?: "start" | "top" | "end" | "bottom";
	setValueOnChange?: any;
	datas: {
		label: string;
		value: string;
		disabled: boolean;
	}[];
}

const RadioPattern: React.FC<IRadioPatternProps> = (props) => {
	function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
		props.setValueOnChange(e.target.value);
	}

	return (
		<FormControl>
			<FormLabel id="demo-radio-buttons-group-label">{props.label}</FormLabel>
			<RadioGroup
				row={props.row}
				aria-labelledby="demo-radio-buttons-group-label"
				defaultValue={props.defaultChecked}
				name="radio-buttons-group"
				onChange={handleChange}
			>
				{props.datas.map((data) => (
					<FormControlLabel
						value={data.value}
						control={<Radio />}
						label={data.label}
						labelPlacement={props.labelPlacement}
						disabled={data.disabled}
					/>
				))}
			</RadioGroup>
		</FormControl>
	);
};

export default RadioPattern;
