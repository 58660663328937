import { Box } from "@mui/material";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import DashboardNavBar from "../../components/navbar/DashboardNavBar/DashboardNavBar";
import LiensInternes from "../../services/liens";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { useTranslation } from "react-i18next";
import DashboardDst from "./DashboardDst/DashboardDst";
import DetailsDemande from "../UserFinal/DetailsDemande/DetailsDemande";
import ToutesDemandes from "../UserFinal/ToutesDemandes/ToutesDemandes";
import DemandeAttenteAvis from "./DemandeAttenteAvis/DemandeAttenteAvis";
import AffecterAgentDst from "./AffecterAgentDst/AffecterAgentDst";
import LayoutMessagerie from "../../components/Messagerie/LayoutMessagerie";
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import ToutesMesDemandes from "./ToutesDemandes/ToutesMesDemandes";

const drawerWidth = 240;

function LayoutDst() {
	const { t } = useTranslation();

	const listItem = [
		{
			nom: t("Tableau de bord"),
			url: LiensInternes.DST.BASE + LiensInternes.DST.DASHBOARD,
			picto: <InboxIcon />,
		},
		{
			nom: "Voir les demandes en catégorie C",
			url: LiensInternes.DST.BASE + LiensInternes.DST.CATEGORIE_C,
			picto: <MailIcon />,
		},
		{
			nom: "Voir les demandes en catégorie B",
			url: LiensInternes.DST.BASE + LiensInternes.DST.CATEGORIE_B,
			picto: <MailIcon />,
		},
		{
			nom: "Voir toutes les demandes",
			url: LiensInternes.DST.BASE + LiensInternes.DST.TOUTES_CATEGORIES,
			picto: <MailIcon />,
		},
		{
			nom: "Affecter des fonctionnaires à des demandes",
			url: LiensInternes.DST.BASE + LiensInternes.DST.AFFECTER_AGENT,
			picto: <MailIcon />,
		},
		{
			nom: "Messagerie",
			url: LiensInternes.DST.BASE + LiensInternes.MESSAGERIE,
			picto: <MarkUnreadChatAltIcon />,
		},
	];
	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "space-between",
				width: "100%",
				position: "relative",
				zIndex: 1
			}}
		>

			<DashboardNavBar listItem={listItem} />
			<Box
				component="main"
				className="layout-dashboard"
				sx={{
					width: { sm: `calc(100% - ${drawerWidth}px)` },
				}}
			>
				<Routes>
					<Route path="/" element={<Navigate to={LiensInternes.DST.BASE + LiensInternes.DST.DASHBOARD} />} />
					<Route path={LiensInternes.DST.DASHBOARD} element={<DashboardDst />} />
					<Route path={LiensInternes.DST.CATEGORIE_C} element={<DemandeAttenteAvis />} />
					<Route path={LiensInternes.DST.CATEGORIE_B} element={<DemandeAttenteAvis />} />
					<Route path={LiensInternes.DETAIL_DEMANDE + "/:id"} element={<DetailsDemande role={"dst"} />} />
					<Route path={LiensInternes.DST.AFFECTER_AGENT} element={<AffecterAgentDst />} />
					<Route path={LiensInternes.DST.BASE + LiensInternes.MESSAGERIE} element={<LayoutMessagerie />} />
					<Route path={LiensInternes.DST.TOUTES_CATEGORIES} element={<ToutesMesDemandes />} />
				</Routes>
			</Box>
		</Box>
	);
}

export default LayoutDst;
