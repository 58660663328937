import * as React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import { useTranslation } from "react-i18next";
import RadioPattern from "../../components/formPatterns/radioPattern/RadioPattern";
import TextFieldPattern from "../../components/formPatterns/textFieldPattern/TextFieldPattern";
import DatePickerPattern from "../../components/formPatterns/datePickerPattern/DatePickerPattern";
import SelectPattern from "../../components/formPatterns/selectPattern/SelectPattern";

interface IStep1 {
	isAnAgent: boolean;
	setProfessionUser?: any;
	professionUser?: string
}

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));

function Step1({ isAnAgent, setProfessionUser, professionUser }: IStep1) {
	const { t } = useTranslation();

	const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		console.log("Selected:", e.target.value);
		setProfessionUser(e.target.value)
	  };

	return (
		isAnAgent ?
			<Grid container spacing={2}>
				<Grid xs={6}>
					<Item sx={{ pr: 3 }}>
						<RadioPattern
							label={t("CREATION_VISA.STEP1.gender")}
							row={true}
							datas={[
								{
									label: t("CREATION_VISA.STEP1.homme"),
									value: "homme",
									disabled: false,
								},
								{
									label: t("CREATION_VISA.STEP1.femme"),
									value: "femme",
									disabled: false,
								},
							]}
						/>
						<TextFieldPattern label={t("CREATION_VISA.STEP1.prenom")} />
						<TextFieldPattern label={t("CREATION_VISA.STEP1.nom")} />
						<DatePickerPattern
							label={t("CREATION_VISA.STEP1.dateNaissance")}
						/>
						<SelectPattern
							label={t("CREATION_VISA.STEP1.pays")}
							data={[
								{
									value: "Argentine",
									label: t("PAYS.argentine"),
								},
								{
									value: "Chypre",
									label: t("PAYS.chypre"),
								},
								{
									value: "Japon",
									label: t("PAYS.japon"),
								},
								{
									value: "Mexique",
									label: t("PAYS.mexique"),
								},
								{
									value: "Mongolie",
									label: t("PAYS.mongolie"),
								},
								{
									value: "Qatar",
									label: t("PAYS.qatar"),
								},
							]}
						/>
						<SelectPattern
							label={t("CREATION_VISA.STEP1.nationalité")}
							data={[
								{
									value: "Argentine",
									label: t("NATIONALITE.argentine"),
								},
								{
									value: "Chypre",
									label: t("NATIONALITE.chypre"),
								},
								{
									value: "Japon",
									label: t("NATIONALITE.japon"),
								},
								{
									value: "Mexique",
									label: t("NATIONALITE.mexique"),
								},
								{
									value: "Mongolie",
									label: t("NATIONALITE.mongolie"),
								},
								{
									value: "Qatar",
									label: t("NATIONALITE.qatar"),
								},
							]}
						/>
					</Item>
				</Grid>
				<Grid xs={6}>
					<Item sx={{ pr: 3, pt: 9 }}>
						<TextFieldPattern
							label={t("CREATION_VISA.STEP1.telephone")}
						/>
						<TextFieldPattern
							label={t("CREATION_VISA.STEP1.adresse")}
						/>
						<SelectPattern
							onChange={handleSelectChange}
							label={t("CREATION_VISA.STEP1.profession")}
							data={[
								{
									value: "Marin",
									label: t("CREATION_VISA.STEP1.marin"),
								},
								{
									value: "Technicien",
									label: t("CREATION_VISA.STEP1.technicien"),
								},
								{
									value: "Autre",
									label: t("CREATION_VISA.STEP1.autre"),
								},
							]}
						/>
						<TextFieldPattern
							label={t("CREATION_VISA.STEP1.numeroPassport")}
						/>
					</Item>
				</Grid>
			</Grid> :
			<Grid container spacing={2}>
				<Grid xs={6}>
					<Item sx={{ pr: 3 }}>
						<RadioPattern
							label={t("CREATION_VISA.STEP1.gender")}
							row={true}
							datas={[
								{
									label: t("CREATION_VISA.STEP1.homme"),
									value: "homme",
									disabled: false,
								},
								{
									label: t("CREATION_VISA.STEP1.femme"),
									value: "femme",
									disabled: false,
								},
							]}
						/>
						<TextFieldPattern label={t("CREATION_VISA.STEP1.prenom")} />
						<TextFieldPattern label={t("CREATION_VISA.STEP1.nom")} />
						<DatePickerPattern
							label={t("CREATION_VISA.STEP1.dateNaissance")}
						/>
						<SelectPattern
							label={t("CREATION_VISA.STEP1.pays")}
							data={[
								{
									value: "Argentine",
									label: t("PAYS.argentine"),
								},
								{
									value: "Chypre",
									label: t("PAYS.chypre"),
								},
								{
									value: "Japon",
									label: t("PAYS.japon"),
								},
								{
									value: "Mexique",
									label: t("PAYS.mexique"),
								},
								{
									value: "Mongolie",
									label: t("PAYS.mongolie"),
								},
								{
									value: "Qatar",
									label: t("PAYS.qatar"),
								},
							]}
						/>
						<SelectPattern
							label={t("CREATION_VISA.STEP1.nationalité")}
							data={[
								{
									value: "Argentine",
									label: t("NATIONALITE.argentine"),
								},
								{
									value: "Chypre",
									label: t("NATIONALITE.chypre"),
								},
								{
									value: "Japon",
									label: t("NATIONALITE.japon"),
								},
								{
									value: "Mexique",
									label: t("NATIONALITE.mexique"),
								},
								{
									value: "Mongolie",
									label: t("NATIONALITE.mongolie"),
								},
								{
									value: "Qatar",
									label: t("NATIONALITE.qatar"),
								},
							]}
						/>
					</Item>
				</Grid>
				<Grid xs={6}>
					<Item sx={{ pr: 3, pt: 9 }}>
						<TextFieldPattern
							label={t("CREATION_VISA.STEP1.telephone")}
						/>
						<TextFieldPattern
							label={t("CREATION_VISA.STEP1.adresse")}
						/>
						<TextFieldPattern
							label={t("CREATION_VISA.STEP1.profession")}
						/>
						<TextFieldPattern
							label={t("CREATION_VISA.STEP1.numeroPassport")}
						/>
					</Item>
				</Grid>
			</Grid>
	);
}

export default Step1;
