import { Box, Grid, Alert, List, Button, Typography, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import ListeDetails from "./ListeDetails";
import "./DetailDemandeComponent.scss";
import TextFieldPattern from "../formPatterns/textFieldPattern/TextFieldPattern";
import RadioPattern from "../formPatterns/radioPattern/RadioPattern";
import SwitchDetailAvancement from "./SwitchDetailAvancement/SwitchDetailAvancement";
import { useSelector } from "react-redux";
import { IRedux } from "../../redux/reducers";
import { IMarin } from "../../redux/Interfaces/typeMarin";
import { IUser } from "../../redux/Interfaces/typeUser";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	margin: "1rem",
	color: theme.palette.text.secondary,
}));

interface IDetailDemandeComponent {
	role: "client" | "agent" | "dst" | "ministere" | "paf";
	id: number;
}

function DetailDemandeComponent({ role, id }: IDetailDemandeComponent) {
	const { t } = useTranslation();

	const [userSelected, setUserSelected] = React.useState<IMarin | IUser>();

	const user = useSelector((state: IRedux) => state.user);
	const marin = useSelector((state: IRedux) => state.marin);

	const [value, setValue] = React.useState("Rien à signaler. L'avis est favorable.");

	const handleChange = (event: any) => {
		setValue(event.target.value);
	};

	useEffect(() => {
		let marinSelected = marin.filter((m: IMarin) => m.id === id);
		let userSelected = user.filter((u: IUser) => u.id === id);
		marinSelected.length !== 0 && setUserSelected(marinSelected[0]);
		userSelected.length !== 0 && setUserSelected(userSelected[0]);
	}, [id]);

	const [step, setStep] = React.useState<"valider" | "refuser" | "cours">("valider");

	const liste = [
		{
			label: t("CREATION_VISA.STEP1.gender"),
			value: t("CREATION_VISA.STEP1.homme"),
		},
		{
			label: t("CREATION_VISA.STEP1.prenom"),
			value: userSelected?.user.prenom,
		},
		{
			label: t("CREATION_VISA.STEP1.nom"),
			value: userSelected?.user.nom,
		},
		{
			label: t("CREATION_VISA.STEP1.dateNaissance"),
			value: userSelected?.user.dateNaissance,
		},
		{
			label: t("CREATION_VISA.STEP1.pays"),
			value: t("PAYS.argentine"),
		},
		{
			label: t("CREATION_VISA.STEP1.nationalité"),
			value: t("NATIONALITE.argentine"),
		},
		{
			label: t("CREATION_VISA.STEP1.telephone"),
			value: userSelected?.user.telephone,
		},
		{
			label: t("CREATION_VISA.STEP1.email"),
			value: userSelected?.user.email,
		},
		{
			label: t("CREATION_VISA.STEP1.adresse"),
			value: userSelected?.user.adresse,
		},
		{
			label: t("CREATION_VISA.STEP1.profession"),
			value: userSelected?.user.profession,
		},
		{
			label: t("CREATION_VISA.STEP1.numeroPassport"),
			value: userSelected?.user.numeroPassport,
		},
		{
			label: t("CREATION_VISA.STEP1.doc1"),
			value: "bouton",
			btnPdf: "passport",
		},
		{
			label: t("CREATION_VISA.STEP1.doc2"),
			value: "bouton",
			btnPdf: "billet",
		},
		{
			label: t("CREATION_VISA.STEP1.doc3"),
			value: "bouton",
			btnPdf: "hebergement",
		},
	];

	if (userSelected?.role === "marin") {
		liste.push({
			label: t("CREATION_VISA.STEP1.doc4"),
			value: "bouton",
			btnPdf: "seamanbook",
		});
	}

	console.log(role === "agent" && userSelected?.category === "B");

	return (
		<div>
			<Grid
				container
				spacing={2}
				sx={{
					marginTop: "2rem",
				}}
			>
				<Grid xs={7}>
					<Item>
						<Typography
							variant="h6"
							component="div"
							sx={{
								flexGrow: 1,
							}}
						>
							{t("USER_FINAL.detailDemande.laDemande")}
						</Typography>

						<Box
							sx={{
								position: "relative",
								textAlign: "left",
								width: "100%",
							}}
						>
							<List
								sx={{
									padding: "1rem",
								}}
							>
								{liste.map((l) => (
									<ListeDetails label={l.label} value={l.value} btnPdf={l.btnPdf && l.btnPdf} />
								))}
							</List>
						</Box>
					</Item>
				</Grid>
				<Grid xs={5}>
					<Item>
						{(role === "client" ||
							role === "agent" ||
							(role === "paf" && userSelected?.category === "C")) && (
								<>
									<Typography
										variant="h6"
										component="div"
										sx={{
											flexGrow: 1,
										}}
									>
										{t("USER_FINAL.detailDemande.avancement")}
									</Typography>

									{/* <RadioPattern
									label={""}
									defaultChecked={step}
									row={true}
									datas={[
										{
											label: "Validé",
											value: "valider",
											disabled: false,
										},
										{
											label: "Refusé",
											value: "refuser",
											disabled: false,
										},
										{
											label: "En Cours",
											value: "cours",
											disabled: false,
										},
									]}
									setValueOnChange={setStep}
								/> */}

									<SwitchDetailAvancement step={userSelected ? userSelected.step : 0} />
								</>
							)}
						{(role === "ministere" || (role === "paf" && userSelected?.category === "B")) && (
							<>
								<Typography
									variant="h6"
									component="div"
									sx={{
										flexGrow: 1,
									}}
								>
									{t("MINISTERE.details.avisDst")}
								</Typography>
								<Typography
									variant="body1"
									component="div"
									sx={{
										flexGrow: 1,
										marginTop: 2,
										marginBottom: 5
									}}
								>
									Après vérification, l'avis est favorable pour un visa de 25 jours.
								</Typography>

								<Typography
									variant="h6"
									component="div"
									sx={{
										flexGrow: 1,
										marginTop: "1rem",
									}}
								>
									{t("DST.detailDemande.interpol")}
								</Typography>
								<TextField
									value={value}
									onChange={handleChange}
									variant="outlined"
									fullWidth
									sx={{
										marginTop: "2%"
									}}
								/>
								{role === "ministere" &&
									<><Button variant="contained">Valider</Button><Button variant="contained">Refuser</Button></>}
							</>
						)}
						{role === "dst" && (
							<>
								<Typography
									variant="h6"
									component="div"
									sx={{
										flexGrow: 1,
									}}
								>
									{t("DST.detailDemande.title")}
								</Typography>
								<TextFieldPattern label={"Ajouter votre avis ici"} multipline={true} rowMultiline={6} />
								<Button variant="contained">{t("DST.detailDemande.bouton")}</Button>

								<Typography
									variant="h6"
									component="div"
									sx={{
										flexGrow: 1,
										marginTop: "1rem",
									}}
								>
									{t("DST.detailDemande.interpol")}
								</Typography>
								<TextField
									value={value}
									onChange={handleChange}
									variant="outlined"
									fullWidth
									sx={{
										marginTop: "2%"
									}}
								/>
							</>
						)}
					</Item>
				</Grid>
			</Grid>
		</div>
	);
}

export default DetailDemandeComponent;
