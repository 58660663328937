import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Badge, Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Typography } from "@mui/material";
import "./TablePattern.scss";
import { useTranslation } from "react-i18next";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import QuizIcon from '@mui/icons-material/Quiz';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { useNavigate } from "react-router-dom";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import MailIcon from "@mui/icons-material/Mail";
import { IUser } from "../../redux/Interfaces/typeUser";
import { IMarin } from "../../redux/Interfaces/typeMarin";
import { stepAvancement } from "../../services/functions/step";

interface ITablePattern {
	data: IMarin[] | IUser[];
	buttonVoirTout: boolean;
	btnNavigateUrl?: string;
	btnMessagerieUrl?: string;
	btnVoirPlusUrl: string;
	role?: "client" | "autorite" | "agent" | "PAF" | "ministere";
	checkbox?: boolean;
}

function TablePattern({
	data,
	buttonVoirTout,
	btnNavigateUrl,
	btnVoirPlusUrl,
	role,
	checkbox,
	btnMessagerieUrl,
}: ITablePattern) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [openModale, setOpenModale] = useState(false);

	return (
		<div className="container-table">
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					{buttonVoirTout && (
						<caption>
							<Button variant="contained" onClick={() => btnNavigateUrl && navigate(btnNavigateUrl)}>
								{t("USER_FINAL.table.voirPlus")}
							</Button>
						</caption>
					)}
					<TableHead>
						<TableRow>
							<TableCell align="center">{t("LISTE.date")}</TableCell>
							{(role === "PAF" && window.location.href.includes("liste-demandes")) && <TableCell align="center">{t("LISTE.dateArrivée")}</TableCell>}
							{(role === "PAF" && window.location.href.includes("liste-demandes")) && <TableCell align="center">{t("LISTE.dateDépart")}</TableCell>}
							{(role === "PAF" && window.location.href.includes("liste-demandes")) && <TableCell align="center">{t("LISTE.duréeSéjour")}</TableCell>}
							{(role === "autorite" || role === "PAF") && <TableCell align="center">{t("LISTE.agentDossierDst")}</TableCell>}
							{(role === "autorite" || role === "PAF") && (
								<TableCell align="center">{t("LISTE.agentDossierMinistere")}</TableCell>
							)}
							{role !== "client" && <TableCell align="center">{t("LISTE.nomPersonne")}</TableCell>}
							<TableCell align="center">{t("LISTE.caterory")}</TableCell>
							{role === "PAF" ? null : <TableCell align="center">{t("LISTE.etape")}</TableCell>}
							{(role === "client" || role === "agent") && (
								<TableCell align="center">{t("LISTE.messagerie")}</TableCell>
							)}
							{role === "ministere" && <TableCell align="center">{t("LISTE.actions")}</TableCell>}
							<TableCell align="center">{t("LISTE.voirPLus")}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{data.map((row) => (
							<TableRow
								key={row.id}
								sx={{
									"&:last-child td, &:last-child th": {
										border: 0,
									},
									opacity: row.step < 3 ? 1 : 0.5,
								}}
							>
								<TableCell component="th" scope="row" align="center">
									<div style={{ display: "flex", justifyContent: "center" }}>
										{checkbox && (
											<Checkbox
												edge="start"
												// checked={checked}
												// onChange={() => setChecked(!checked)}
												tabIndex={-1}
												disableRipple
											/>
										)}

										{row.new && role === "autorite" && (
											<NewReleasesIcon color="error" sx={{ marginRight: "0.7rem" }} />
										)}
										{/* {row.step === "En Cours" && !row.new && role !== "autorite" && (
											<NewReleasesIcon color="success" sx={{ marginRight: "0.7rem" }} />
										)} */}
										<Typography
											variant="body1"
											component="div"
											sx={{
												flexGrow: 1,
												display: "inline-block",
											}}
										>
											{row.request_date}
										</Typography>
									</div>
								</TableCell>
								{(role === "PAF" && window.location.href.includes("liste-demandes")) && <TableCell align="center">{row.arrival_date}</TableCell>}
								{(role === "PAF" && window.location.href.includes("liste-demandes")) && <TableCell align="center">{row.departure_date}</TableCell>}
								{(role === "PAF" && window.location.href.includes("liste-demandes")) && <TableCell align="center">{row.durationStay}</TableCell>}
								{(role === "autorite" || role === "PAF") && <TableCell align="center">{row.nomAgentDst}</TableCell>}
								{(role === "autorite" || role === "PAF") && <TableCell align="center">{row.nomAgentMinistere}</TableCell>}
								{role !== "client" && (
									<TableCell align="center">{row.user.prenom + " " + row.user.nom}</TableCell>
								)}
								<TableCell align="center">
									{t("LISTE.caterory")} {row.category}
								</TableCell>
								{role === "PAF" ? null : <TableCell align="center">{stepAvancement(row.step)}</TableCell>}

								{(role === "client" || role === "agent") && (
									<TableCell align="center" onClick={() => navigate(btnMessagerieUrl + "/")}>
										<Badge badgeContent={row.message} color="primary" className="badge-mail">
											<MailIcon color="action" />
										</Badge>
									</TableCell>
								)}
								{role === "ministere" && 
								<TableCell align="center">
									<Button onClick={() => setOpenModale(true)}>
										<QuizIcon />
									</Button>
								</TableCell>}
								<Dialog open={openModale} onClose={() => setOpenModale(false)}>
									<DialogTitle sx={{ display: 'flex', alignItems: 'center' }}> <QuestionAnswerIcon sx={{ marginRight: 2 }} />{t("LISTE.modaleTitle")}</DialogTitle>
									<DialogContent>
										<DialogContentText>
											{t("LISTE.modaleDescription")}
										</DialogContentText>
									</DialogContent>
									<DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
										<Button onClick={() => {
											setOpenModale(false);
										}}>
											Annuler
										</Button>
										<Button onClick={() => {
											setOpenModale(false);
										}}>
											Demander
										</Button>
									</DialogActions>
								</Dialog>
								<TableCell align="center">
									<Button onClick={() => navigate(btnVoirPlusUrl + "/" + row.id)}>
										<AddCircleOutlineIcon />
									</Button>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
}

export default TablePattern;
