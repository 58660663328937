import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import image from "../../pictures/aliunix-PnTVEMOJtLo-unsplash.jpg";
import image2 from "../../pictures/passport-g9f774914e_1920.jpg";
import ButtonPattern from "../../components/Boutons/ButtonPattern";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import ListAltIcon from '@mui/icons-material/ListAlt';
import LiensInternes from "../../services/liens";
import "./Home.scss";

function SliderHome() {
	const { t } = useTranslation();
	const navigate = useNavigate();

	// Lien externe test
	const handleClick = () => {
		window.location.href = "https://www.ifla.org/wp-content/uploads/2019/05/assets/hq/news/documents/unesco-annex-fr.pdf";
	};

	const settings = {
		dots: false,
		infinite: true,
		arrows: false,
		swipeToSlide: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
        autoplaySpeed: 10000,
		speed: 500,
	};

	return (
		<div>
			<Slider {...settings}>
				<div className="slide">
					<div className="container-image">
						<img src={image} alt="image_header" className="image" />
						<Box className="container-title">
							<Typography
								align="center"
								variant="h2"
								component="div"
								sx={{
									flexGrow: 1,
									mb: 3
								}}
							>
								{t("NAVBAR.nom_application")}
							</Typography>
							<Typography
								align="center"
								variant="h6"
								component="div"
								sx={{ flexGrow: 1 }}
								dangerouslySetInnerHTML={{ __html: t("HOME.descriptionHome") }}
							/>
							<ButtonPattern
								title={t("HOME.boutons.demande_visa")}
								icon={<CreditScoreIcon />}
								size={"large"}
								onClick={() => navigate(LiensInternes.CREATION_VISA + LiensInternes.STEP_00)}
							/>
						</Box>
					</div>
				</div>
				<div className="slide">
					<div className="container-image">
						<img src={image2} alt="image_header" className="image" />
						<Box className="container-title">
							<Typography
								align="center"
								variant="h2"
								component="div"
								sx={{
									flexGrow: 1,
									mb: 3
								}}
							>
								{t("HOME.titleCategorie")}
							</Typography>
							<Typography
								align="center"
								variant="h6"
								component="div"
								sx={{ flexGrow: 1 }}
								dangerouslySetInnerHTML={{ __html: t("HOME.descriptionCategorie") }}
							/>
							<ButtonPattern
								title={t("HOME.boutons.consulter_liste_pays")}
								icon={<ListAltIcon />}
								size={"large"}
								onClick={handleClick}
							/>
						</Box>
					</div>
				</div>
			</Slider>
		</div>
	)

}

export default SliderHome;