import {
	AppBar,
	Box,
	Button,
	IconButton,
	Toolbar,
	Typography,
	Grid
} from "@mui/material";
import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import "./HeaderNavBar.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import LiensInternes from "../../services/liens";
import image from "../../pictures/senegal-g14b3a6b5f_1280.png";

function HeaderNavBar() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	return (
		<Box sx={{ flexGrow: 1, marginBottom: 5 }}>
			<AppBar position="fixed" className="nav-bar">
				<Toolbar>
				<Grid item xs={12} sm={6} md={1} textAlign="center">
					<Box
						component="img"
						sx={{
							maxHeight: { xs: 50, md: 50 },
							maxWidth: { xs: 50, md: 50 },
							p: 2
						}}
						alt="image Sénégal"
						src={image}
					/>
				</Grid>
					<Typography
						variant="h6"
						component="div"
						sx={{ flexGrow: 1 }}
						onClick={() => navigate(LiensInternes.ACCEUIL)}
					>
						{t("NAVBAR.nom_application")}
					</Typography>
					<Button
						color="inherit"
						onClick={() => navigate(LiensInternes.CONNEXION)}
						sx={{marginRight: { xs: 5, md: 10 } }}
					>
						{t("NAVBAR.boutons.bouton_login")}
					</Button>
				</Toolbar>
			</AppBar>
		</Box>
	);
}

export default HeaderNavBar;
