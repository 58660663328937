import { Button } from "@mui/material";
import React from "react";

interface IButtonPattern {
	title: string;
	icon: any;
	size: "large";
	onClick: any;
}

function ButtonPattern({ title, icon, size, onClick }: IButtonPattern) {
	return (
		<Button
			sx={{
				backgroundColor: "#004792",
				marginTop: "2%"
			}}
			className="button"
			variant="contained"
			startIcon={icon}
			size={size}
			onClick={onClick}
		>
			{title}
		</Button>
	);
}

export default ButtonPattern;
