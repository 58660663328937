import { Button, Typography, Grid, IconButton, CardContent, TextField, Box } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useNavigate } from "react-router-dom";
import LiensInternes from "../../../services/liens";
import Swal from "sweetalert2";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    margin: "2rem",
    height: "15rem",
    width: "15rem",
    color: theme.palette.text.secondary,
}));

function NouvelleDemande() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div>
            <Typography
                variant="h3"
                component="div"
                sx={{
                    flexGrow: 1,
                }}
            >
                {t("USER_FINAL.nouvelleDemande.title")}
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column", marginTop: 5 }}>
                <Typography
                    variant="h6"
                    component="div"
                    sx={{
                        flexGrow: 1,
                    }}
                >
                    {t("USER_FINAL.nouvelleDemande.descriptionPage")}
                </Typography>
                <Button onClick={() => navigate(LiensInternes.CREATION_VISA)} sx={{ height: 45, maxWidth: "18%", margin: "0 auto", marginTop: 8}} variant="contained" color="primary" >
                    <AddCircleIcon sx={{ marginRight: 1 }} />{t("USER_FINAL.nouvelleDemande.boutonCreerDemande")}
                </Button>
            </Box>
        </div>
    );
}

export default NouvelleDemande;
