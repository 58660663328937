import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import TableAgentGroup from "../../../components/TableAgentGroup/TableAgentGroup";
import { IRedux } from "../../../redux/reducers";
import LiensInternes from "../../../services/liens";

function DashboardStatAgent() {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const marins = useSelector((state: IRedux) => state.marin);
	return (
		<div>
			{" "}
			<Typography
				variant="h3"
				component="div"
				sx={{
					flexGrow: 1,
				}}
			>
				{t("AGENT.dashboard.title")}
			</Typography>
			<TableAgentGroup
				data={[
					{ id: 1453442787, date: "10-10-2023", step: "En préparation", new: false, group: "Reel it Fev 2023", nbPersonne: 2 },
					{ id: 24566558, date: "10-10-2023", step: "En préparation", new: false, group: "CMA CGM Janv 2023	", nbPersonne: 30 },
					{ id: 37867674586, date: "10-10-2023", step: "En préparation", new: false, group: "Hcube Janv 2023	", nbPersonne: 7 },
				]}
				buttonVoirTout={true}
				btnNavigateUrl={LiensInternes.AGENT.BASE + LiensInternes.AGENT.SUIVRE_DEMANDE}
				btnVoirPlusUrl={
					LiensInternes.AGENT.BASE + LiensInternes.AGENT.SUIVRE_DEMANDE + LiensInternes.DETAIL_GROUP
				}
			/>
		</div>
	);
}

export default DashboardStatAgent;
