import React from "react";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

interface IDatePickerPattern {
	label: string;
}

function DatePickerPattern({ label }: IDatePickerPattern) {
	const [value, setValue] = React.useState<Dayjs | null>(dayjs("2022-04-17"));
	return (
		<div>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<DemoContainer components={["DatePicker", "DatePicker"]}>
					<DatePicker 				sx={{
					margin: "0.5rem",
				}} 
						label={label}
						defaultValue={dayjs("2022-04-17")}
					/>
				</DemoContainer>
			</LocalizationProvider>
		</div>
	);
}

export default DatePickerPattern;
