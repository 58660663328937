import { TRUE } from "sass";
import { TYPES } from "../actions/actionTypes";
import { IMarin, MarinAction, MarinState } from "../Interfaces/typeMarin";

const initialState: MarinState = [
  {
    id: 100,
    role: "marin",
    request_date: "01/01/2023",
    arrival_date: "07/01/2023",
    departure_date: "30/12/2022",
    durationStay: "12",
    step: 2,
    new: true,
    category: "B",
    user: {
      id: 1,
      nom: "Vilma",
      prenom: "Bastien",
      dateNaissance: "10/10/1988",
      pays: "France",
      nationalite: "Français",
      telephone: "06.58.13.65.47",
      email: "b.vilma@gmail.com",
      adresse: "16 rue de Napoléon 13000 Marseille",
      profession: "Développeur",
      numeroPassport: "94849623587456598451265984",
    },
    nomAgentDst: "John Doe",
    nomAgentMinistere: "Marielle Provo",
    message: 2
  },
  {
    id: 101,
    role: "marin",
    request_date: "12/12/2022",
    arrival_date: "21/12/2022",
    departure_date: "30/12/2022",
    durationStay: "12",
    step: 1,
    new: false,
    category: "B",
    user: {
      id: 1,
      nom: "Vilma",
      prenom: "Bastien",
      dateNaissance: "10/10/1988",
      pays: "France",
      nationalite: "Français",
      telephone: "06.58.13.65.47",
      email: "b.vilma@gmail.com",
      adresse: "16 rue de Napoléon 13000 Marseille",
      profession: "Développeur",
      numeroPassport: "94849623587456598451265984",
    },
    nomAgentDst: "John Doe",
    nomAgentMinistere: "Marielle Provo",
    message: 3
  },
  {
    id: 102,
    role: "marin",
    request_date: "10/12/2022",
    arrival_date: "20/12/2022",
    departure_date: "30/12/2022",
    durationStay: "12",
    step: 3,
    new: false,
    category: "C",
    user: {
      id: 1,
      nom: "Vilma",
      prenom: "Bastien",
      dateNaissance: "10/10/1988",
      pays: "France",
      nationalite: "Français",
      telephone: "06.58.13.65.47",
      email: "b.vilma@gmail.com",
      adresse: "16 rue de Napoléon 13000 Marseille",
      profession: "Développeur",
      numeroPassport: "94849623587456598451265984",
    },
    nomAgentDst: "John Doe",
    nomAgentMinistere: "Marielle Provo",
    message: 0
  },
  {
    id: 103,
    role: "marin",
    request_date: "09/12/2022",
    arrival_date: "18/12/2022",
    departure_date: "30/12/2022",
    durationStay: "12",
    step: 3,
    new: false,
    category: "C",
    user: {
      id: 1,
      nom: "Vilma",
      prenom: "Bastien",
      dateNaissance: "10/10/1988",
      pays: "France",
      nationalite: "Français",
      telephone: "06.58.13.65.47",
      email: "b.vilma@gmail.com",
      adresse: "16 rue de Napoléon 13000 Marseille",
      profession: "Développeur",
      numeroPassport: "94849623587456598451265984",
    },
    nomAgentDst: "John Doe",
    nomAgentMinistere: "Marielle Provo",
    message: 0
  },
  {
    id: 104,
    role: "marin",
    request_date: "23/11/2022",
    arrival_date: "03/12/2022",
    departure_date: "30/12/2022",
    durationStay: "12",
    step: 2,
    new: false,
    category: "C",
    user: {
      id: 1,
      nom: "Vilma",
      prenom: "Bastien",
      dateNaissance: "10/10/1988",
      pays: "France",
      nationalite: "Français",
      telephone: "06.58.13.65.47",
      email: "b.vilma@gmail.com",
      adresse: "16 rue de Napoléon 13000 Marseille",
      profession: "Développeur",
      numeroPassport: "94849623587456598451265984",
    },
    nomAgentDst: "John Doe",
    nomAgentMinistere: "Marielle Provo",
    message: 0
  },
  {
    id: 105,
    role: "marin",
    request_date: "20/11/2022",
    arrival_date: "01/12/2022",
    departure_date: "30/12/2022",
    durationStay: "12",
    step: 3,
    new: false,
    category: "B",
    user: {
      id: 1,
      nom: "Vilma",
      prenom: "Bastien",
      dateNaissance: "10/10/1988",
      pays: "France",
      nationalite: "Français",
      telephone: "06.58.13.65.47",
      email: "b.vilma@gmail.com",
      adresse: "16 rue de Napoléon 13000 Marseille",
      profession: "Développeur",
      numeroPassport: "94849623587456598451265984",
    },
    nomAgentDst: "John Doe",
    nomAgentMinistere: "Marielle Provo",
    message: 0
  },
]

const marinReducer = (
  state: MarinState = initialState,
  action: MarinAction
): MarinState => {
  switch (action.type) {
    // case TYPES.USER.ADD:
    //   const newUser: IUser = {
    //     id: Math.random(), // not really unique
    //     email: action.user.email,
    //     password: action.user.password,
    //   }
    //   return {
    //     ...state,
    //     user: state.user.concat(newUser),
    //   }
    // case TYPES.USER.REMOVE:
    //   const removeUser: IMarin[] = state.user.filter(
    //     usr => usr.id !== action.marin.id
    //   )
    //   return {
    //     ...state,
    //     user: removeUser,
    //   }
  }
  return state
}

export default marinReducer
