import { Box, Button, Divider, Grid, Link, Typography } from "@mui/material";
import image from "../../../pictures/senegal-g14b3a6b5f_1280.png";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
	footer: {
		backgroundColor: "white",
		borderTop: '1px solid rgba(0, 0, 0, 0.12)',
		padding: "2%",
		marginTop: "2%",
		position: 'relative',
		zIndex: 1
	},
	socialButton: {
		marginRight: 2,
		marginBottom: 2,
	},
	contactInfo: {
		marginBottom: 2,
	},
	divider: {
		margin: 4,
	},
	copyright: {
		marginTop: 4,
	},
}));

function Footer() {
	const classes = useStyles();

	return (
		<footer className={classes.footer}>
			<Grid container justifyContent="space-between">
				<Grid item xs={12} sm={6} md={1} textAlign="center">
					<Box
						component="img"
						sx={{
							height: 233,
							width: 350,
							maxHeight: { xs: 233, md: 150 },
							maxWidth: { xs: 350, md: 250 },
						}}
						alt="image Sénégal"
						src={image}
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={3}>
					<Typography variant="h5">VISA SENEGAL</Typography>
					<Typography variant="body1" color="textSecondary">
						Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, in error sapiente dolorem quidem
						sed nobis eius porro quis aperiam deleniti quae asperiores cumque, nostrum voluptas corporis
						officia, ducimus illo?
					</Typography>
				</Grid>
				<Grid item xs={12} sm={6} md={1} textAlign="center" sx={{ display: "flex", flexDirection: "column" }}>
					<Typography variant="h5" mb={1}>
						Liens utiles
					</Typography>
					<Link href="#" color="textSecondary" mb={1}>
						Accueil
					</Link>
					<Link href="#" color="textSecondary" mb={1}>
						À propos
					</Link>

					<Link href="#" color="textSecondary" mb={1}>
						Services
					</Link>
				</Grid>
				<Grid item xs={12} sm={6} md={2}>
					<Typography variant="h5">Nous contacter</Typography>
					<Typography variant="body2" color="textSecondary">
						Adresse : Lorem ipsum dolor sit amet
						<br />
						Ville : Dakar
						<br />
						Pays : Sénégal
						<br />
						Téléphone : +33 1 23 45 67 89
						<br />
						Email : contact@monentreprise.com
					</Typography>
				</Grid>
			</Grid>
			<Divider style={{ margin: "20px 0" }} />
			<Typography variant="body2" color="textSecondary" align="center">
				© Nom entreprise {new Date().getFullYear()}. Tous droits réservés.
			</Typography>
		</footer>
	);
}

export default Footer;
