
import { combineReducers } from "redux";
import { MesDemandesState } from "../Interfaces/typeMesDemandes";
import { UserState } from "../Interfaces/typeUser";
import mesDemandesReducer from "./mesDemandesReducer";
import marinReducer from "./userMarinReducer";
import userReducer from "./userReducer";

export interface IRedux {
	user: UserState;
	marin: UserState;
	mesDemandes: MesDemandesState;
}


const rootReducer = combineReducers({
	user: userReducer,
	marin: marinReducer,
	mesDemandes: mesDemandesReducer,
});

export default rootReducer;
