import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Badge, Button, Checkbox, Typography } from "@mui/material";
import "../Table/TablePattern.scss";
import { useTranslation } from "react-i18next";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useNavigate } from "react-router-dom";
import MailIcon from "@mui/icons-material/Mail";
import { IMesDemandes } from "../../redux/Interfaces/typeMesDemandes";
import { stepAvancement } from "../../services/functions/step";

interface ITableMesDemandes {
	data: IMesDemandes[];
	buttonVoirTout: boolean;
	btnNavigateUrl?: string;
	btnMessagerieUrl?: string;
	btnVoirPlusUrl: string;
	role?: "client" | "autorite" | "agent";
	checkbox?: boolean;
}

function TableMesDemandes({
	data,
	buttonVoirTout,
	btnNavigateUrl,
	btnVoirPlusUrl,
	role,
	checkbox,
	btnMessagerieUrl,
}: ITableMesDemandes) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	return (
		<div className="container-table">
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					{buttonVoirTout && (
						<caption>
							<Button variant="contained" onClick={() => btnNavigateUrl && navigate(btnNavigateUrl)}>
								{t("USER_FINAL.table.voirPlus")}
							</Button>
						</caption>
					)}
					<TableHead>
						<TableRow>
							<TableCell align="left">{t("LISTE.date")}</TableCell>
							<TableCell align="center">{t("LISTE.etape")}</TableCell>
							{(role === "client" || role === "agent") && (
								<TableCell align="center">{t("LISTE.messagerie")}</TableCell>
							)}
							<TableCell align="center">{t("LISTE.voirPLus")}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{data.map((row) => (
							<TableRow
								key={row.id}
								sx={{
									"&:last-child td, &:last-child th": {
										border: 0,
									},
									opacity: row.step < 3 ? 1 : 0.5,
								}}
							>
								<TableCell component="th" scope="row" align="left">
									<div style={{ display: "flex", justifyContent: "center" }}>
										{checkbox && (
											<Checkbox
												edge="start"
												// checked={checked}
												// onChange={() => setChecked(!checked)}
												tabIndex={-1}
												disableRipple
											/>
										)}
										<Typography
											variant="body1"
											component="div"
											sx={{
												flexGrow: 1,
												display: "inline-block",
											}}
										>
											{row.date}
										</Typography>
									</div>
								</TableCell>
								<TableCell align="center">{stepAvancement(row.step)}</TableCell>

								{(role === "client" || role === "agent") && (
									<TableCell align="center" onClick={() => navigate(btnMessagerieUrl + "/" )}>
										<Badge badgeContent={row.message} color="primary" className="badge-mail">
											<MailIcon color="action" />
										</Badge>
									</TableCell>
								)}

								<TableCell align="center">
									<Button onClick={() => navigate(btnVoirPlusUrl + "/" + row.id)}>
										<AddCircleOutlineIcon />
									</Button>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
}

export default TableMesDemandes;
