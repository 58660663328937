import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import TablePattern from "../../../components/Table/TablePattern";
import { IUser } from "../../../redux/Interfaces/typeUser";
import { IRedux } from "../../../redux/reducers";
import LiensInternes from "../../../services/liens";

function DemandeAttenteAvis() {
	const { t } = useTranslation();
	const user = useSelector((state: IRedux) => state.user);

	const [allUser, setAllUser] = useState<IUser[]>([]);

	useEffect(() => {
		window.location.href.includes("-b")
			? setAllUser(user.filter((u: IUser) => u.category === "B"))
			: setAllUser(user.filter((u: IUser) => u.category === "C"));
	});
	return (
		<div>
			<Typography
				variant="h3"
				component="div"
				sx={{
					flexGrow: 1,
				}}
			>
				{t("DST.enAttenteAvis.title")}
			</Typography>
			<TablePattern
				role="autorite"
				checkbox={true}
				data={allUser}
				buttonVoirTout={false}
				btnVoirPlusUrl={LiensInternes.DST.BASE + LiensInternes.DETAIL_DEMANDE}
			/>
		</div>
	);
}

export default DemandeAttenteAvis;
