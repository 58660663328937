import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import TableMesDemandes from "../../../components/TableMesDemandes/TableMesDemandes";
import { IMesDemandes } from "../../../redux/Interfaces/typeMesDemandes";
import { IRedux } from "../../../redux/reducers";
import LiensInternes from "../../../services/liens";

function DashboardUserFinal() {
	const { t } = useTranslation();

	const mesDemandes = useSelector((state: IRedux) => state.mesDemandes);

	return (
		<div>
			{" "}
			<Typography
				variant="h3"
				component="div"
				sx={{
					flexGrow: 1,
				}}
			>
				{t("USER_FINAL.dashboard.title")}
			</Typography>
			<TableMesDemandes
				role="client"
				data={mesDemandes.filter((md: IMesDemandes) => md.step < 3)}
				buttonVoirTout={true}
				btnNavigateUrl={LiensInternes.USER.BASE + LiensInternes.USER.SUIVRE_DEMANDE}
				btnVoirPlusUrl={LiensInternes.USER.BASE + LiensInternes.USER.SUIVRE_DEMANDE}
				btnMessagerieUrl={LiensInternes.USER.BASE + LiensInternes.MESSAGERIE}
			/>
		</div>
	);
}

export default DashboardUserFinal;
