import Typography from "@mui/material/Typography";
import { t } from "i18next";
import React from "react";
import DropDownComponent from "../../components/DropDownComponent/DropDownComponent";
import { grey } from '@mui/material/colors';

interface IStep3 {
	isAnAgent: boolean;
	professionUser?: String;
}

function Step3({ isAnAgent, professionUser }: IStep3) {
	console.log("profession", professionUser);
	
	return (
		<> {isAnAgent ?
			<>
				<Typography sx={{ margin: 4 }}>{t("CREATION_VISA.STEP3.titleAgent")}</Typography><div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridColumnGap: '1px' }}>
					{professionUser === "Marin" ?
						<div>
							<Typography sx={{ marginLeft: 9 }} variant="h6" gutterBottom>{t("CREATION_VISA.STEP3.livretMarin")}</Typography>
							<DropDownComponent />
						</div> : null}

					<div>
						<Typography sx={{ marginLeft: 9 }} variant="h6" gutterBottom>{t("CREATION_VISA.STEP3.reservation")}</Typography>
						<DropDownComponent />
					</div>

					<div>
						<Typography sx={{ marginLeft: 9 }} variant="h6" gutterBottom>{t("CREATION_VISA.STEP3.passeport")}</Typography>
						<DropDownComponent />
					</div>

					<div>
						<Typography sx={{ marginLeft: 9 }} variant="h6" gutterBottom>{t("CREATION_VISA.STEP3.billetTransport")}</Typography>
						<DropDownComponent />
					</div>

					<div>
						<Typography sx={{ marginLeft: 9 }} variant="h6" gutterBottom>{t("CREATION_VISA.STEP3.lettreGarantie")}</Typography>
						<DropDownComponent />
					</div>
					{professionUser === "Technicien" ?
					<div>
						<Typography sx={{ marginLeft: 9 }} variant="h6" gutterBottom>{t("CREATION_VISA.STEP3.lettreInformations")}</Typography>
						<DropDownComponent />
					</div> : null}
					<div>
						<Typography sx={{ marginLeft: 9 }} variant="h6" gutterBottom>{t("CREATION_VISA.STEP3.autre")}</Typography>
						<DropDownComponent />
					</div>

					<Typography sx={{	
						textAlign: 'center',
						fontStyle: 'italic',
						color: grey[500],
						gridColumn: '1 / -1',
					}}>
						{t("CREATION_VISA.STEP3.indication")}
					</Typography>

					<br />
				</div></> :
			<>
				<Typography sx={{ margin: 4 }}>{t("CREATION_VISA.STEP3.title")}</Typography><div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gridColumnGap: '16px' }}>


					<div>
						<Typography sx={{ marginLeft: 5 }} variant="h6" gutterBottom>{t("CREATION_VISA.STEP3.billetTransport")}</Typography>
						<DropDownComponent />
					</div>

					<div>
						<Typography sx={{ marginLeft: 5 }} variant="h6" gutterBottom>{t("CREATION_VISA.STEP3.reservation")}</Typography>
						<DropDownComponent />
					</div>

					<div>
						<Typography sx={{ marginLeft: 5 }} variant="h6" gutterBottom>{t("CREATION_VISA.STEP3.passeport")}</Typography>
						<DropDownComponent />
					</div>

					<div>
						<Typography sx={{ marginLeft: 5 }} variant="h6" gutterBottom>{t("CREATION_VISA.STEP3.autre")}</Typography>
						<DropDownComponent />
					</div>

					<Typography sx={{
						textAlign: 'center',
						fontStyle: 'italic',
						color: grey[500],
						gridColumn: '1 / -1',
					}}>
						{t("CREATION_VISA.STEP3.indication")}
					</Typography>

					<br />
				</div></>}</>
	);
}

export default Step3;
