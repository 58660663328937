import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import TablePattern from "../../../components/Table/TablePattern";
import { IUser } from "../../../redux/Interfaces/typeUser";
import { IRedux } from "../../../redux/reducers";
import LiensInternes from "../../../services/liens";

function PafCategoryB() {
	const { t } = useTranslation();

	const user = useSelector((state: IRedux) => state.user);

	return (
		<div>
			<Typography
				variant="h3"
				component="div"
				sx={{
					flexGrow: 1,
				}}
			>
				{t("PAF.categoryB.title")}
			</Typography>

			<TablePattern
				role="PAF"
				data={user}
				buttonVoirTout={false}
				btnVoirPlusUrl={LiensInternes.PAF.BASE + LiensInternes.DETAIL_DEMANDE}
			/>
		</div>
	);
}

export default PafCategoryB;
