import React from "react";
import { useDropzone } from "react-dropzone";
import "./DropDownComponent.scss";
import { useTranslation } from "react-i18next";

function DropDownComponent() {
	const { t } = useTranslation();
	const { getRootProps, getInputProps, acceptedFiles } = useDropzone({});
	const files = acceptedFiles.map((file) => (
		<li key={file.name}>
			{file.name} - {file.size} bytes
		</li>
	));
	return (
		<div>
			<div
				{...getRootProps({ className: "dropzone" })}
				className="dropzone"
			>
				<input className="input-zone" {...getInputProps()} />
				<div className="text-center">
					<p className="dropzone-content">
					{t("DRAG_N_DROP.texteExplicatif")}
					</p>
				</div>
			</div>
			<ul>{files}</ul>
		</div>
	);
}

export default DropDownComponent;
