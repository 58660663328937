import { Button, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import CheckboxPattern from "../../../components/formPatterns/checkboxPattern/CheckboxPattern";
import RadioPattern from "../../../components/formPatterns/radioPattern/RadioPattern";
import TablePattern from "../../../components/Table/TablePattern";
import LiensInternes from "../../../services/liens";
import "./AffecterAgentDst.scss";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { IRedux } from "../../../redux/reducers";

function AffecterAgentDst() {
	const { t } = useTranslation();
	const user = useSelector((state: IRedux) => state.user);

	function handleClick() {
		Swal.fire("Demande(s) affectée(s) avec succès !");
	}
	return (
		<div style={{ position: "relative" }}>
			<Button variant="contained" size="large" className="bouton-affecter" onClick={handleClick}>
				{t("DST.affecterAgent.bouton")}
			</Button>
			<Typography
				variant="h3"
				component="div"
				sx={{
					flexGrow: 1,
				}}
			>
				{t("DST.affecterAgent.title")}
			</Typography>
			<Typography
				variant="h6"
				component="div"
				sx={{
					flexGrow: 1,
					padding: "1rem",
				}}
			>
				{t("DST.affecterAgent.selectionAgent")}
			</Typography>

			<RadioPattern
				label={""}
				row={true}
				datas={[
					{ label: "Paul Vilo ", value: "Vilo", disabled: false },
					{ label: "Marine Bune", value: "Bune", disabled: false },
					{ label: "John Doe", value: "Doe", disabled: false },
				]}
			/>

			<Typography
				variant="h6"
				component="div"
				sx={{
					flexGrow: 1,
					padding: "1rem",
				}}
			>
				{t("DST.affecterAgent.selectionDemandes")}
			</Typography>
			<TablePattern
				role="autorite"
				checkbox={true}
				data={user}
				buttonVoirTout={false}
				btnVoirPlusUrl={LiensInternes.DST.BASE + LiensInternes.DETAIL_DEMANDE}
			/>
		</div>
	);
}

export default AffecterAgentDst;
