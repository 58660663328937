import React from "react";
import DashboardNavBar from "../../components/navbar/DashboardNavBar/DashboardNavBar";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Navigate, Route, Routes } from "react-router-dom";
import LiensInternes from "../../services/liens";
import DashboardStatAgent from "./DashBoardAgent/DashboardStatAgent";
import DemandesAgent from "./DemandesAgent/DemandesAgent";
import SuivreDemandesAgent from "./SuivreDemandesAgent/SuivreDemandesAgent";
import DetailsGroup from "./DetailsGroup/DetailsGroup";
import DetailsDemande from "../UserFinal/DetailsDemande/DetailsDemande";
import LayoutMessagerie from "../../components/Messagerie/LayoutMessagerie";
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';

const drawerWidth = 240;

function DashboardAgent() {
	const { t } = useTranslation();

	const listItem = [
		{
			nom: t("Tableau de bord"),
			url: LiensInternes.AGENT.BASE + LiensInternes.AGENT.DASHBOARD,
			picto: <InboxIcon />,
		},
		{
			nom: "Création d'une demande",
			url: LiensInternes.AGENT.BASE + LiensInternes.AGENT.NEW_DEMANDE,
			picto: <MailIcon />,
		},
		{
			nom: "Toutes mes demandes",
			url: LiensInternes.AGENT.BASE + LiensInternes.AGENT.SUIVRE_DEMANDE,
			picto: <MailIcon />,
		},
		{
			nom: "Messagerie",
			url: LiensInternes.AGENT.BASE + LiensInternes.MESSAGERIE,
			picto: <MarkUnreadChatAltIcon />,
		},
	];

	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "space-between",
				width: "100%",
				position: "relative",
				zIndex: 1
			}}
		>
			<DashboardNavBar listItem={listItem} />
			<Box
				component="main"
				className="layout-dashboard"
				sx={{
					width: { sm: `calc(100% - ${drawerWidth}px)` },
				}}
			>
				<Routes>
					<Route
						path="/"
						element={<Navigate to={LiensInternes.AGENT.BASE + LiensInternes.AGENT.DASHBOARD} />}
					/>
					<Route path={LiensInternes.AGENT.DASHBOARD} element={<DashboardStatAgent />} />
					<Route path={LiensInternes.AGENT.NEW_DEMANDE} element={<DemandesAgent />} />
					<Route path={LiensInternes.AGENT.SUIVRE_DEMANDE} element={<SuivreDemandesAgent />} />
					<Route
						path={LiensInternes.AGENT.SUIVRE_DEMANDE + LiensInternes.DETAIL_GROUP}
						element={<DetailsGroup />}
					/>
					<Route path={LiensInternes.DETAIL_DEMANDE + "/:id"} element={<DetailsDemande role={"agent"} />} />
					<Route path={LiensInternes.AGENT.BASE + LiensInternes.MESSAGERIE} element={<LayoutMessagerie />} />
				</Routes>
			</Box>
		</Box>
	);
}

export default DashboardAgent;
