import { Button, Divider, ListItem, ListItemText } from "@mui/material";
import React, { useEffect } from "react";
import ModalePatternPdf from "../ModalePattern/ModalePatternPdf/ModalePatternPdf";
import passport from "../../pictures/passport.jpg";
import billet from "../../pictures/billet_avion.jpg";
import hebergement from "../../pictures/hotel.jpg";
import seamanbook from "../../pictures/seamanbook.jpg";

interface IListeDetails {
	label: string;
	value: string;
	btnPdf?: "passport" | "billet" | "hebergement" | "seamanbook" | string;
}

function ListeDetails({ label, value, btnPdf }: IListeDetails) {
	const [open, setOpen] = React.useState(false);
	const [pdf, setPdf] = React.useState(passport);

	useEffect(() => {
		btnPdf === "passport" && setPdf(passport);
		btnPdf === "billet" && setPdf(billet);
		btnPdf === "hebergement" && setPdf(hebergement);
		btnPdf === "seamanbook" && setPdf(seamanbook);
	}, []);

	let test = "";
	if (btnPdf === "hebergement") {
		test = hebergement;
	}

	return (
		<>
			<ListItem
				disablePadding
				secondaryAction={
					value === "bouton" ? (
						<Button size="small" variant="contained" onClick={() => setOpen(true)}>
							Voir le document
						</Button>
					) : (
						<ListItemText primary={value} />
					)
				}
			>
				<ListItemText primary={label} />
			</ListItem>
			<Divider />

			{open && <ModalePatternPdf open={open} setOpen={setOpen} sourcePdf={pdf} />}
		</>
	);
}

export default ListeDetails;
