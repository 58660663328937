export function stepAvancement(step) {
	switch (step) {
		case 1:
			return "En attente de l'avis de la DST";
		case 2:
			return "En traitement au ministère";
		case 3:
			return "Votre demande de visa a bien été délivré";
		case 4:
			return "Votre demande de visa a été refusé";
		default:
			return "Non connu";
	}
}
